import {
  AppBar,
  Box,
  Drawer,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";

const formatFieldName = (string) => {
  string = string.replace(/([A-Z])/g, " $1");
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function ItemDetail({ data, _onClose }) {
  return (
    <Drawer anchor="right" open={data !== null} onClose={_onClose}>
      <Box width={600}>
        {data && (
          <div>
            <AppBar position="sticky" sx={{ py: 1 }}>
              <Grid container alignItems="center">
                <Grid item>
                  <IconButton onClick={_onClose}>
                    <ArrowBackIcon fontSize="small" sx={{ fill: "#fff" }} />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="body1">Back</Typography>
                </Grid>
              </Grid>
            </AppBar>
            <TableContainer>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Key</TableCell>
                    <TableCell>Value</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(data)
                    .sort()
                    .map((key, index) => {
                      return (
                        key !== "properties" && (
                          <TableRow key={index}>
                            <TableCell>{formatFieldName(key)}</TableCell>
                            <TableCell>{JSON.stringify(data[key])}</TableCell>
                          </TableRow>
                        )
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </Box>
    </Drawer>
  );
}
