import { AppBar, Grid, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import FundList from "../../pages/fund/FundList";
import FundLogs from "../../pages/fund/FundLogs";
import NFTList from "../../pages/nft/NFTList";

export default function UserTransaction({ data }) {
  const [tab, setTab] = useState(0);

  const menus = [
    // {
    //   title: "Referrals",
    //   component: <Referrals />,
    // },

    {
      title: "NFT Items",
      component: <NFTList isProfile={true} ownerAddress={data.address} />,
    },
    // {
    //   title: "Box",
    //   component: <BoxList isProfile={true} ownerAddress={userAddress} />,
    // },
    // {
    //   title: `Transactions`,
    //   component: <Transactions isProfile={true} profileUserId={userId} />,
    // },
    // {
    //   title: `Staking`,
    //   component: <StakingList isProfile={true} profileUserId={userId} />,
    // },
    // {
    //   title: "Funds",
    //   component: <FundList userId={data.id} />,
    // },
    // {
    //   title: "Fund logs",
    //   component: <FundLogs userId={data.id} />,
    // },
    // {
    //   title: "Deposit",
    //   component: <DepositHistory isProfile={true} profileUserId={userId} />,
    // },
    // {
    //   title: "Withdraw/Transfer",
    //   component: <WithdrawHistory isProfile={true} profileUserId={userId} />,
    // },
    // {
    //   title: `Affiliate Commission`,
    //   component: <AffiliateCommission />,
    // },
  ];

  const _handleChange = (e, value) => setTab(value);

  return (
    <Grid item xs={12}>
      <AppBar position="static" color="default" variant="outlined">
        <Tabs
          value={tab}
          onChange={_handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {menus.map((item, index) => (
            <Tab key={index} label={item.title} value={index}></Tab>
          ))}
        </Tabs>
      </AppBar>
      {menus[tab]?.component}
    </Grid>
  );
}
