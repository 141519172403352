import React from 'react';
import PropTypes from 'prop-types';
import { Card, IconButton, Modal, styled, Typography } from '@mui/material';
import { IconX } from "@tabler/icons"

CustomPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  background: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const PopupStyle = styled(Modal)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export default function CustomPopup({ open = false, title, closeButton, onClose, background = false, backgroundColor, children, width, sx = {} }) {
  return (
    <PopupStyle open={open} onClose={() => onClose()}>
      {background ? (
        <Card
          sx={{
            outline: 'none',
            p: 2,
            borderRadius: "10px",
            background: backgroundColor || '#fff',
            width: width || 380,
            maxHeight: '60vh',
            overflow: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            ...sx,
          }}
        >
          {
            title && <Typography variant='h6'>{title}</Typography>
          }
          {
            closeButton && <IconButton onClick={() => onClose()} sx={{ position: "absolute", top: 10, right: 10 }}><IconX /></IconButton>
          }
          {children}
        </Card>
      ) : (
        <>
          {
            title && <Typography variant='h6'>{title}</Typography>
          }
          {
            closeButton && <IconButton onClick={() => onClose()} sx={{ position: "absolute", top: 10, right: 10 }}><IconX /></IconButton>
          }
          {children}
        </>
      )}
    </PopupStyle>
  );
}
