import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// ----------------------------------------------------------------------

ScopeValidate.propTypes = {
  validScopes: PropTypes.array,
  children: PropTypes.node,
};

export default function ScopeValidate({ validScopes = [], children }) {
  const { user } = useSelector((state) => state);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    if (user && user.profile) {
      const { profile } = user;
      const { scopes } = profile;
      let temp = false;
      validScopes.forEach((scope) => {
        if (scopes.includes(scope)) temp = true;
      });
      setValid(temp);
    }
  }, [user, validScopes]);
  if (validScopes.includes('ALL')) return children;
  if (valid) return children;
  return null;
}
