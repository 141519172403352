import { CheckCircle } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  Icon2fa,
  IconBan,
  IconBarcode,
  IconCornerUpRight,
  IconLineDashed,
  IconLock,
  IconLockOpen,
  IconPencil,
  IconRepeat,
  IconTrash,
  IconX,
} from "@tabler/icons";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ACTIVE, BLOCK, NEW } from "../constants";
import { EndpointConstant } from "../constants/endpoint";
import { formatAddress, formatNumber, fStatus } from "../settings/format";
import { library } from "../settings/library";
import { _switchPopup } from "../store/setting/settingActions";
import { post, put } from "../utils/api";
import CustomLoading from "./CustomLoading";
import ItemDetail from "./ItemDetail";
import LinkToHash from "./LinkToHash";
import LinkToOwnerAddress from "./LinkToOwnerAddress";
import LinkToTokenId from "./LinkToTokenId";

const _formatData = (row, col, enums) => {
  let result = row[col.key];
  const {
    isAmount,
    isTime,
    isType,
    isStatus,
    isDate,
    isGA,
    isHash,
    isArray,
    isBool,
    isImage,
    isUsed,
    isOwnerAddress,
    isEnum,
    enumKey,
    isUserAddress,
    isFile,
    isSold,
    isNFTTokenId,
    isAddress,
    isUserStatus,
    isContent,
    isMedias,
    isNFT,
    isCreator,
  } = col;
  const value = row[col.key];
  if (enums && isEnum && enumKey) {
    let result = [];
    Object.keys(enums[enumKey]).forEach((key) => {
      result[enums[enumKey][key]] = key;
    });
    result = result[value];
    if (isStatus) {
      result = fStatus(result.toUpperCase(), result);
    }
    return result;
  }
  if (isCreator) {
    result = <LinkToOwnerAddress address={value.address} email={value.email} />;
  }
  if (isImage) {
    result = (
      <img
        src={row[col.key]}
        width={50}
        height={50}
        style={{
          objectFit: "contain",
        }}
        alt=""
      />
    );
  }
  if (!row[col.key] && row[col.key] !== 0) {
    result = <IconLineDashed stroke={2} style={{ width: 16, height: 16 }} />;
  }
  if (isUserAddress || isOwnerAddress || isAddress) {
    result = <LinkToOwnerAddress address={row[col.key]} />;
  }
  if (isHash) {
    result = <LinkToHash txHash={value} />;
  }
  if (isContent) result = formatAddress(value);
  if (isMedias) {
    result = (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto auto",
          columnGap: "5px",
          rowGap: "5px",
          "& img": {
            maxWidth: "100px",
          },
        }}
      >
        {value.map((item) => (
          <img
            src={item.url}
            alt=""
            width={"100px"}
            height={100}
            style={{ objectFit: "cover" }}
          />
        ))}
      </Box>
    );
  }
  if (isAmount) result = formatNumber(row[col.key]);
  if (isTime) result = moment(row[col.key]).format("YYYY-MM-DD HH:mm:ss UTC");
  if (isDate) result = moment(row[col.key]).format("YYYY-MM-DD");
  if (isType || isStatus)
    result = library[row[col.key]] ? library[row[col.key]] : row[col.key];
  if (isGA) result = fStatus(row.gaEnable, row.gaEnable ? "ON" : "OFF");
  if (isUsed)
    result = row[col.key] ? (
      <CheckCircle color="primary" fontSize="small" />
    ) : null;
  if (isArray) result = row[col.key].toString();
  if (isBool) {
    result = fStatus(row[col.key], row[col.key] ? "ACTIVE" : "INACTIVE");
  }
  if (isUserStatus) {
    result = fStatus(!value, !value ? "ACTIVE" : "INACTIVE");
  }
  if (isNFT) {
    result = fStatus(!value, !value ? "NFT" : "None NFT");
  }
  if (isSold) {
    let totalSold = 0;
    value.forEach((v) => (totalSold += v.sold));
    result = totalSold;
  }
  if (isFile) {
    if (row.isVideo) {
      result = (
        <video
          src={row[col.key]}
          width={50}
          height={50}
          style={{
            objectFit: "contain",
          }}
          alt=""
        />
      );
    } else {
      result = (
        <img
          src={row[col.key]}
          width={50}
          height={50}
          style={{
            objectFit: "contain",
          }}
          alt=""
        />
      );
    }
  }
  if (isNFTTokenId) {
    result = <LinkToTokenId tokenId={value} />;
  }
  return result;
};

function CustomTable({
  columns,
  data,
  updateFields,
  updateGA,
  deleteEndpoint,
  _handleSelectItem,
  _handleEditItem,
  _handleUpdateGA,
  _handleDelete,
  reMintEndpoint,
  _handleReMint,
  _onReload,
  ...props
}) {
  const [rows, setRows] = useState(null);
  const [selectedBoxDetail, setSelectedBoxDetail] = useState(null);
  const { setting } = useSelector((state) => state);
  const { enums } = setting;
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setRows(data.items);
    } else {
      setRows(null);
    }
  }, [data]);

  const _handleBanUser = (row) => {
    dispatch(
      _switchPopup({
        title: `${row.status ? "Un-ban" : "Ban"} user #` + row.id,
        content: "Are you for this action",
        _handleSubmit: () => {
          put(
            EndpointConstant.USER_BAN,
            { userId: row.id, isActive: Boolean(row.status) },
            () => {
              toast.success("Success");
              _onReload();
            },
            (error) => {
              toast.error(error.title + ": " + error.detail);
            }
          );
        },
      })
    );
  };

  const _handleUnlockNFT = (row) => {
    dispatch(
      _switchPopup({
        title: `Unlock NFT #` + row.tokenId,
        content: "Are you for this action",
        _handleSubmit: () => {
          post(
            EndpointConstant.UNLOCK_NFT,
            { tokenId: row.tokenId, inDb: false },
            () => {
              toast.success("Success");
              _onReload();
            },
            (error) => {
              toast.error(error.title + ": " + error.detail);
            }
          );
        },
      })
    );
  };

  const _handleLockNFT = (row) => {
    dispatch(
      _switchPopup({
        title: `Lock NFT #` + row.tokenId,
        content: "Are you for this action",
        _handleSubmit: () => {
          post(
            EndpointConstant.LOCK_NFT,
            { tokenId: row.tokenId, inDb: false },
            () => {
              toast.success("Success");
              _onReload();
            },
            (error) => {
              toast.error(error.title + ": " + error.detail);
            }
          );
        },
      })
    );
  };

  const _handleReMintNFT = (row) => {
    dispatch(
      _switchPopup({
        title: `Re-mint NFT #` + row.tokenId,
        content: "Are you for this action",
        _handleSubmit: () => {
          post(
            EndpointConstant.ReMintingNFT,
            { tokenId: row.tokenId, inDb: false },
            () => {
              toast.success("Success");
              _onReload();
            },
            (error) => {
              toast.error(error.title + ": " + error.detail);
            }
          );
        },
      })
    );
  };

  const _handleApproveWithdraw = (row) => {
    dispatch(
      _switchPopup({
        title: `Approve withdraw #` + row.id,
        content: "Are you for this action",
        _handleSubmit: () => {
          post(
            EndpointConstant.ApproveWithdraw,
            { balanceLogId: row.id },
            () => {
              toast.success("Success");
              _onReload();
            },
            (error) => {
              toast.error(error.title + ": " + error.detail);
            }
          );
        },
      })
    );
  };

  const _handleCancelWithdraw = (row) => {
    dispatch(
      _switchPopup({
        title: `Cancel withdraw #` + row.id,
        content: "Are you for this action",
        _handleSubmit: () => {
          post(
            EndpointConstant.CancelWithdraw,
            { balanceLogId: row.id },
            () => {
              toast.success("Success");
              _onReload();
            },
            (error) => {
              toast.error(error.title + ": " + error.detail);
            }
          );
        },
      })
    );
  };

  return (
    <>
      <Table stickyHeader aria-label="sticky table" size="small">
        <TableHead>
          <TableRow>
            {columns.map((item, index) => (
              <TableCell key={index} style={{ fontWeight: "700" }}>
                {item.label}
              </TableCell>
            ))}
            <TableCell sx={{ position: "sticky", right: 0 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows &&
            rows.map((row, index) => {
              let statusColor = null;
              if (row.status === ACTIVE) {
                statusColor = "green-color";
              }
              if (row.status === BLOCK) {
                statusColor = "red-color";
              }
              if (row.status === NEW) {
                statusColor = "blue-color";
              }
              return (
                <TableRow key={index}>
                  {columns.map((col, colIndex) => {
                    return (
                      <TableCell
                        key={colIndex}
                        className={col.key === "status" ? statusColor : ""}
                      >
                        {col.render
                          ? col.render(row)
                          : _formatData(row, col, enums)}
                      </TableCell>
                    );
                  })}
                  <TableCell
                    sx={{ position: "sticky", right: 0, background: "#fff" }}
                  >
                    {!row.inDb && !row.mintTxHash && props.hasReMint && (
                      <IconButton
                        size="small"
                        onClick={() => _handleReMintNFT(row)}
                        color="success"
                      >
                        <IconRepeat fontSize="small" />
                      </IconButton>
                    )}
                    {updateFields && (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => _handleEditItem(row)}
                      >
                        <IconPencil />
                      </IconButton>
                    )}

                    {deleteEndpoint && (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => _handleDelete(row)}
                      >
                        <IconTrash fontSize="small" />
                      </IconButton>
                    )}
                    {reMintEndpoint && (
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => _handleReMint(row)}
                      >
                        <RefreshIcon fontSize="small" />
                      </IconButton>
                    )}
                    <IconButton
                      color="primary"
                      onClick={() => _handleSelectItem(row)}
                    >
                      <IconBarcode />
                    </IconButton>
                    {props.isBanUser && (
                      <IconButton
                        size="small"
                        color={!row.status ? "error" : "success"}
                        onClick={() => _handleBanUser(row)}
                      >
                        <IconBan fontSize="small" />
                      </IconButton>
                    )}
                    {props.hasUnlockNFT &&
                      (row.status ? (
                        row.lockTxHash !== "" && (
                          <Tooltip title="UnLock NFT">
                            <IconButton
                              size="small"
                              color="error"
                              onClick={() => _handleUnlockNFT(row)}
                            >
                              <IconLock fontSize="small" />
                              {}
                            </IconButton>
                          </Tooltip>
                        )
                      ) : (
                        <Tooltip title="Lock NFT">
                          <IconButton
                            size="small"
                            onClick={() => _handleLockNFT(row)}
                            color="success"
                          >
                            <IconLockOpen fontSize="small" />
                          </IconButton>
                        </Tooltip>
                      ))}
                    {updateGA && row?.gaEnable && (
                      <Tooltip title="Turn Off GA">
                        <IconButton
                          size="small"
                          color="primary"
                          onClick={() => _handleUpdateGA(row)}
                        >
                          <Icon2fa />
                        </IconButton>
                      </Tooltip>
                    )}
                    {props.isWithdrawal &&
                      (row.status === 4 || row.status === 6) && (
                        <Tooltip title="Approve withdraw">
                          <IconButton
                            size="small"
                            color="success"
                            onClick={() => _handleApproveWithdraw(row)}
                          >
                            <IconCornerUpRight />
                          </IconButton>
                        </Tooltip>
                      )}
                    {props.isWithdrawal &&
                      (row.status === 1 || row.status === 4) && (
                        <Tooltip title="Cancel withdraw">
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => _handleCancelWithdraw(row)}
                          >
                            <IconX />
                          </IconButton>
                        </Tooltip>
                      )}
                  </TableCell>
                </TableRow>
              );
            })}
          {data && (data.itemCount === 0 || data.items.length === 0) && (
            <TableRow>
              <TableCell colSpan={columns.length}>No records found</TableCell>
            </TableRow>
          )}
          {!data && (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <CustomLoading />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <ItemDetail
        data={selectedBoxDetail}
        _onClose={() => setSelectedBoxDetail(null)}
      />
    </>
  );
}

export default CustomTable;
