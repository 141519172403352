import { Add } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatAddress_1 } from "../settings/format";

export default function ItemField({
  data,
  defaultScopes,
  defaultData,
  defaultFilter,
  _handleCheck,
  ...props
}) {
  const {
    type,
    col,
    text,
    key,
    require,
    disabled,
    selectName,
    isEnum,
    enumKey,
  } = data;
  const { setting } = useSelector((state) => state);
  const { enums, contracts } = setting;
  const [list, setList] = useState([]);

  useEffect(() => {
    if (enums && contracts) {
      let list = [];
      if (selectName === "PAYMENT_CONTRACTS") {
        list = contracts;
      }
      if (selectName === "MINTING_ROUND") {
        list = [
          0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
          20,
        ];
      }
      if (isEnum && enumKey) {
        const temp = [];
        Object.keys(enums[enumKey]).forEach((key) => {
          temp[enums[enumKey][key]] = key;
        });
        list = temp;
        // list = Object.keys(enums[enumKey]);
        // list = enums[enumKey];
      }
      if (selectName === "ASSET") {
        contracts.forEach((c) => list.push(c.symbol));
      }
      if (selectName === "EXCHANGE_LIST") {
        list = [
          "Binance",
          "Bitfinex",
          "Bitstamp",
          "Bittrex",
          "Coinbase",
          "GateEx",
          "Houbi",
          "Okex",
        ];
      }
      setList(list);
    }
  }, [contracts, enumKey, enums, isEnum, selectName]);

  let defaultValue = "";
  if (defaultData || defaultData === 0) defaultValue = defaultData;
  if (defaultFilter && defaultFilter[key]) defaultValue = defaultFilter[key];

  if (type === "input") {
    return (
      <Grid item xs={col ? col : 2}>
        <TextField
          label={text}
          variant="outlined"
          size="small"
          fullWidth
          name={key}
          required={require}
          defaultValue={defaultData}
          disabled={
            disabled || (data?.checkEditable && defaultData?.canEditable)
          }
          {...props}
        />
      </Grid>
    );
  } else if (type === "select") {
    if (selectName === "PAYMENT_CONTRACTS") {
      return (
        <Grid item xs={col ? col : 2}>
          <TextField
            label={text}
            inputProps={{
              name: key,
            }}
            select
            variant="outlined"
            fullWidth
            size="small"
            defaultValue={defaultValue}
            required={require}
            disabled={disabled}
            {...props}
          >
            <MenuItem value="">
              <em>NONE</em>
            </MenuItem>
            {list.map((item, index) => (
              <MenuItem value={item.contractAddress} key={index}>
                {item.symbol} - {formatAddress_1(item.contractAddress, 18)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    } else if (isEnum) {
      return (
        <Grid item xs={col ? col : 2}>
          <TextField
            label={text}
            inputProps={{
              name: key,
            }}
            select
            variant="outlined"
            fullWidth
            size="small"
            defaultValue={defaultValue}
            required={require}
            disabled={disabled}
            {...props}
          >
            <MenuItem value="">
              <em>NONE</em>
            </MenuItem>
            {list.map((item, index) => (
              <MenuItem value={index} key={index}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    } else {
      return (
        <Grid item xs={col ? col : 2}>
          <TextField
            label={text}
            inputProps={{
              name: key,
            }}
            select
            variant="outlined"
            fullWidth
            size="small"
            defaultValue={defaultValue}
            required={require}
            disabled={disabled}
            {...props}
          >
            <MenuItem value="">
              <em>NONE</em>
            </MenuItem>
            {list.map((item, index) => (
              <MenuItem value={item} key={index}>
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      );
    }
  } else if (type === "date") {
    return (
      <Grid item xs={col ? col : 2}>
        <TextField
          label={text}
          variant="outlined"
          size="small"
          fullWidth
          name={key}
          type="date"
          required={require}
          disabled={disabled}
          InputLabelProps={{
            shrink: true,
          }}
          {...props}
        />
      </Grid>
    );
  } else if (type === "date-time") {
    return (
      <Grid item xs={col ? col : 2}>
        <TextField
          label={text}
          variant="outlined"
          size="small"
          fullWidth
          name={key}
          type="datetime-local"
          required={require}
          disabled={disabled}
          defaultValue={defaultData}
          InputLabelProps={{
            shrink: true,
          }}
          {...props}
        />
      </Grid>
    );
  } else if (type === "checkbox") {
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLabel component="legend">{text}</FormLabel>
          </Grid>
          {list.map((item, index) => (
            <Grid item xs={4} key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={defaultScopes.includes(item)}
                    onChange={_handleCheck}
                    name={item}
                    size="small"
                    disabled={disabled}
                    {...props}
                  />
                }
                label={item}
              />
            </Grid>
          ))}
        </Grid>
      </Grid>
    );
  } else if (type === "singleCheckbox") {
    return (
      <Grid item xs={col ? col : 12}>
        <Box pl={1}>
          <FormControlLabel
            control={
              <Checkbox
                name={key}
                size="small"
                {...props}
                defaultChecked={defaultData}
                disabled={disabled}
              />
            }
            label={text}
          />
        </Box>
      </Grid>
    );
  } else if (type === "special") {
    return <SpecialField defaultValue={defaultValue} {...props} data={data} />;
  } else if (type === "object") {
    return <ObjectFields defaultValue={defaultValue} {...props} data={data} />;
  } else return null;
}

const SpecialField = ({ data, defaultValue }) => {
  const { specialFields } = data;
  const [items, setItems] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      const temp = [];
      defaultValue.map((d) => {
        const item = {};
        specialFields.map((f) => {
          item[f.key] = d[f.key];
          return true;
        });
        temp.push(item);
        return true;
      });
      setItems(temp);
    } else {
      setItems([]);
    }
  }, [defaultValue, specialFields]);

  const _handleChange = (index, field, target) => {
    const temp = [...items];
    if (field.type === "singleCheckbox") {
      temp[index][field.key] = target.checked;
    } else {
      if (field.isEnum) {
        temp[index][field.key] = parseInt(target.value);
      } else {
        temp[index][field.key] = target.value;
      }
    }
    setItems(temp);
  };

  const _addItem = () => {
    const temp = [...items];
    const item = {};
    specialFields.forEach((field) => {
      if (field.type === "singleCheckbox") {
        item[field.key] = false;
      } else {
        item[field.key] = "";
      }
    });
    temp.push(item);
    setItems(temp);
  };

  return (
    <Grid item xs={data.col ? data.col : 2}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{data.text}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={data.key}
              value={JSON.stringify(items)}
              fullWidth
              disabled
            ></TextField>
          </Grid>
          {items?.map((item, index1) => {
            return (
              <Grid item xs={12} key={index1}>
                <Grid container alignItems="center">
                  <Grid item xs={12}>
                    <Grid container spacing={2} alignItems="center">
                      {specialFields.map((field, index2) => {
                        field = {
                          ...field,
                          // disabled: item.canEditable === false ? true : false,
                        };
                        return (
                          <ItemField
                            data={field}
                            key={index2}
                            onChange={(e) =>
                              _handleChange(index1, field, e.target)
                            }
                            defaultData={item[field.key]}
                          />
                        );
                      })}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          <Grid item xs={12}>
            <Button onClick={_addItem} variant="outlined">
              <Add />
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const ObjectFields = ({ data, defaultValue }) => {
  const { specialFields } = data;
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue);
    } else {
      const item = {};
      specialFields.forEach((field) => {
        if (field.type === "singleCheckbox") {
          item[field.key] = false;
        } else {
          item[field.key] = "";
        }
      });
      setValue(item);
    }
  }, [defaultValue, specialFields]);

  const _handleChange = (field, target) => {
    const temp = { ...value };
    if (field.type === "singleCheckbox") {
      temp[field.key] = target.checked;
    } else {
      if (field.isEnum) {
        temp[field.key] = parseInt(target.value);
      } else {
        temp[field.key] = target.value;
      }
    }
    setValue(temp);
  };

  return (
    <Grid item xs={data.col ? data.col : 2}>
      <Paper variant="outlined" sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>{data.text}</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              name={data.key}
              value={JSON.stringify(value)}
              fullWidth
              disabled
            ></TextField>
          </Grid>
          <Grid item xs={12}>
            <Grid container alignItems="center">
              <Grid item xs={12}>
                <Grid container spacing={2} alignItems="center">
                  {specialFields.map((field, index2) => {
                    field = {
                      ...field,
                      disabled:
                        defaultValue.canEditable === false ? true : false,
                    };
                    return (
                      <ItemField
                        data={field}
                        key={index2}
                        onChange={(e) => _handleChange(field, e.target)}
                        defaultData={defaultValue[field.key]}
                      />
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
