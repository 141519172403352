import { get } from "../../utils/api";
import { AdminEndpoint, AdminStoreConstant } from "./adminConstants";

export const _getAdminProfile = () => (dispatch) => {
  get(
    AdminEndpoint.ADMIN_PROFILE,
    (data) => {
      dispatch({
        type: AdminStoreConstant.SET_INFORMATION_LOADING,
        payload: false,
      });
      dispatch({
        type: AdminStoreConstant.GET_PROFILE,
        payload: data,
      });
    },
    () => {
      dispatch({
        type: AdminStoreConstant.SET_INFORMATION_LOADING,
        payload: false,
      });
    }
  );
};
