import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";

const columns = [
  {
    key: "id",
    label: "",
    isId: true,
  },
  {
    key: "userId",
    label: "User ID",
  },
  {
    key: "type",
    label: "Type",
    isEnum: true,
    enumKey: "BalanceTransactionType",
  },
  {
    key: "amount",
    label: "Amount",
    isAmount: true,
  },
  {
    key: "asset",
    label: "Asset",
  },
  // {
  //   key: "description",
  //   label: "Description",
  // },
  {
    key: "status",
    label: "Status",
    isEnum: true,
    enumKey: "BalanceTransactionStatus",
    isStatus: true,
  },
  {
    key: "creationDate",
    label: "Created Time",
    isTime: true,
  },
  {
    key: "modificationDate",
    label: "Modification Date",
    isTime: true,
  },
];

const filterBy = [
  new Filter({
    key: "userId",
    type: "input",
    text: "User ID",
  }),
  new Filter({
    key: "type",
    type: "select",
    text: "Type",
    isEnum: true,
    enumKey: "BalanceTransactionType",
  }),
  new Filter({
    key: "status",
    type: "select",
    text: "Status",
    isEnum: true,
    enumKey: "BalanceTransactionStatus",
  }),
];

const FundLogs = (props) =>
  SearchHigherComponent({
    endpoint: EndpointConstant.FUND_LOGS,
    title: "Fund logs",
    columns,
    filterBy,
    isWithdrawal: true,
    ...props,
  })();

export default FundLogs;
