export const PROJECT_NAME = "SPLabs x Raon";

export const ACCESS_TOKEN_KEY = "BBKfiqudkcb2ion";
export const SCOPES_KEY = "AhBcmvr1EkMdPnL5";

export const USER_SCOPE = ["USER_WRITE", "USER_READ"];
export const NFT_SCOPE = ["NFT_READ", "NFT_WRITE"];
export const FUND_SCOPE = ["FUND_WRITE", "FUND_READ"];
export const ADMIN_SCOPE = ["ADMIN_FULL"];
export const VESTING_SCOPE = ["VESTING_WRITE", "VESTING_READ"];
export const SYSTEM_SCOPE = ["SYSTEM_FULL"];
export const ROLE_SCOPE = ["ROLE_FULL"];
export const STAKING_SCOPE = ["STAKING_WRITE", "STAKING_READ"];
export const INO_SCOPE = ["INO_READ", "INO_WRITE"];

export const ERR_CODE = {
  GACODE_REQUIRED: "Google Authenticator Code is required",
  WRONG_CREDENTIALS: "Incorrect account or password",
  PASSWORD_WRONG: "Wrong password",
  GACODE_WRONG: "Wrong Google Authenticator code",
  RECORD_NOTFOUND: "Record notfound",
};

export const PAGE_SIZE = [10, 25, 50, 100];

const Local = "https://apikt.feliciastation.com/api";
const Production = "https://api-kt-raon.splabs.info/api";

export const isProduction =
  window.location.hostname.indexOf("raonkt-adm.feliciastation.com") < 0 &&
  window.location.hostname.indexOf("localhost") < 0;

export const API = isProduction ? Production : Local;
export const baseUrl = isProduction ? "" : "https://files.feliciastation.com";

export const EXPORTPARAMS = {
  page: 1,
  pageSize: 9000000,
  search: "",
  orderBy: "Id",
  isDesc: true,
  returnStatistic: false,
  filters: {},
};
