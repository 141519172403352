import SearchHigherComponent from "../HOC/SearchHigherComponent";
import { Filter } from "../settings";
import { AdminEndpoint } from "../store/admin/adminConstants";

const columns = [
  {
    key: "id",
    label: "ID",
    isId: true,
  },
  {
    key: "address",
    label: "Address",
    isUserAddress: true,
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "status",
    label: "Status",
    isUserStatus: true,
  },
  {
    key: "creationDate",
    label: "Join time",
    isDate: true,
  },
];

const exportColumns = [
  {
    key: "id",
    label: "ID",
    isId: true,
  },
  {
    key: "address",
    label: "Address",
    isUserAddress: true,
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "displayName",
    label: "displayName",
  },
  {
    key: "phoneNumber",
    label: "Phone",
  },
  {
    key: "userLocationAddress",
    label: "Location Address",
  },
  {
    key: "zipCode",
    label: "ZipCode",
  },
  {
    key: "creationDate",
    label: "Join time",
    isDate: true,
  },
  {
    key: "modificationDate",
    label: "Updated time",
    isDate: true,
  },
];

const filterBy = [
  new Filter({
    key: "email",
    type: "input",
    text: "Email",
  }),
  new Filter({
    key: "address",
    type: "input",
    text: "Address",
  }),
];
export default SearchHigherComponent({
  endpoint: AdminEndpoint.ADMIN_USERS,
  exportLink: "user-list",
  title: "User list",
  columns,
  exportColumns,
  updateEndpoint: AdminEndpoint.ADMIN_USERS_INACTIVE_GA,
  updateGA: true,
  filterBy,
  isBanUser: true,
});
