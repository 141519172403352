import { styled } from "@mui/material/styles";
import { Container, Grid, Box, Typography } from "@mui/material";

import useResponsive from "../hooks/useResponsive";

import Page from "../components/Page";

import { PROJECT_NAME } from "../settings/constants";
import { LoginForm } from "../components/auth/login";

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
  width: "100vw",
  height: "100vh",
  overflow: "clip",
  // background: "linear-gradient(45deg,#acb6e5,#86fde8)",
  backgroundImage: "url(/images/login/bg1.jpg)",
  backgroundSize: "100% 100%",
  backgroundRepeat: "no-repeat",
  display: "flex",
  color: "#c0e4e8",
  [theme.breakpoints.down("md")]: {

    backgroundSize: "cover",
  },
}));

const CusGrid = styled(Grid)(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexDirection: "row",
  padding: theme.spacing(12, 0),
  [theme.breakpoints.down("sm")]: {
    justifyContent: "center",
    flexDirection: "column",
  },
}));

// ----------------------------------------------------------------------

export default function Login() {
  const isMobile = useResponsive("down", "sm");
  return (
    <Page title="Admin">
      <RootStyle>
        <Container maxWidth="lg">
          <CusGrid container>
            <Grid item md={7} sm={6} xs={12}>
              <Box
                component={"img"}
                src="/images/raon-store.png"
                sx={{
                  display: isMobile ? "none" : "block",
                  width: "min(90%,500px)",
                }}
              />
              <Box
                component={"img"}
                src="/images/logo.png"
                sx={{
                  width: "min(50%,400px)",
                  position: "fixed",
                  left: "50%",
                  top: "10%",
                  transform: 'translateX(-50%)',
                }}
              />

              <Box
                component={"img"}
                src="/images/login/cloud.png"
                sx={{
                  position: "fixed",
                  right: "0",
                  bottom: "10%",
                }}
              />
              <Box
                component={"img"}
                src="/images/login/cloud.png"
                sx={{
                  position: "fixed",
                  left: "0",
                  top: "5%",
                  transform: 'scaleX(-1)',
                }}
              />

            </Grid>
            <Grid item md={5} sm={5} xs={12}>
              <Typography variant="h5" gutterBottom color="#28A3AB">
                Sign in to {PROJECT_NAME} Admin
              </Typography>
              <LoginForm />
            </Grid>
          </CusGrid>
        </Container>
      </RootStyle>
    </Page>
  );
}
