
import { useSelector } from 'react-redux';
import {
  Box,
  Typography,
  Avatar,
  IconButton,
} from '@mui/material';

export default function AccountPopover() {
  const { admin } = useSelector((state) => state);
  const { information } = admin;

  return (
    <>
      <Box sx={{ my: 1.5, px: 1, textAlign: 'right' }}>
        <Typography variant="subtitle2" noWrap sx={{ color: 'text.secondary' }}>
          Role: Admin
        </Typography>
        <Typography variant="subtitle2" sx={{ color: 'text.secondary' }} noWrap>
          {information?.email}
        </Typography>
      </Box>
      <IconButton>
        <Avatar src="/images/characters/raon-7.png" alt="photoURL" />
      </IconButton>
    </>
  );
}