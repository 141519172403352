import { combineReducers } from "redux";
import UserReducer from "./reducers/userReducer";
import AppReducer from "./reducers/appReducer";
import AdminReducer from "./admin/adminReducer";
import { SettingReducer } from "./setting/settingReducer";

const rootReducer = combineReducers({
  user: UserReducer,
  app: AppReducer,
  admin: AdminReducer,
  setting: SettingReducer,
});

export default rootReducer;
