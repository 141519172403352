// ----------------------------------------------------------------------

export default function Autocomplete(theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          outline: 'none',
          '&.filter': {
            input: {
              minWidth: '100px !important',
              flexGrow: 'inherit',
            },
            '& .MuiAutocomplete-endAdornment': {
              top: 'unset',
              right: 'unset',
              position: 'relative',
            },
            '& .MuiAutocomplete-popupIndicator': {
              color: '#000 !important',
              transform: 'rotate(0)',
              '&:before': {
                content: '"+"',
                position: 'absolute',
                color: '#637381',
              },
            },
          },
        },
        paper: {
          boxShadow: theme.customShadows.z20,
        },
      },
    },
  };
}
