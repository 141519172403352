import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";

import { useSelector } from "react-redux";
import ComingSoon from "./pages/ComingSoon";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";
import Page404 from "./pages/Page404";
import UserList from "./pages/UserList";
import AdminList from "./pages/admin/AdminList";
import ChangePassword from "./pages/admin/ChangePassword";
import AirdropNFT from "./pages/airdrop/AirdropNFT";
import INOList from "./pages/ino/INOList";
import MallPointList from "./pages/nft/MallPointList";
import NFTList from "./pages/nft/NFTList";
import NFTTransactions from "./pages/nft/NFTTransactions";
import SwapList from "./pages/nft/SwapList";
import SyncSwapJob from "./pages/nft/SyncSwapJob";
export default function Router() {
  const { admin } = useSelector((state) => state);
  const { information } = admin;

  let element = useRoutes([
    {
      path: "/dashboard",
      element: information ? <DashboardLayout /> : <Navigate to="/" />,
      children: [
        {
          path: "/dashboard",
          children: [
            {
              path: "overview",
              element: <Dashboard />,
            },
          ],
        },
        { path: "users", element: <UserList /> },
        {
          path: "nft",
          children: [
            { path: "list", element: <NFTList /> },
            { path: "transactions", element: <NFTTransactions /> },
            { path: "mall-point", element: <MallPointList /> },
            { path: "swap", element: <SwapList /> },
            { path: "sync-swap-job", element: <SyncSwapJob /> },
          ],
        },
        // {
        //   path: "fusion",
        //   children: [
        //     { path: "history", element: <FusionHistory /> },
        //     { path: "ranking", element: <FusionRanking /> },
        //     { path: "settings", element: <FusionSetting /> },
        //   ],
        // },
        // {
        //   path: "m2e",
        //   children: [
        //     { path: "settings", element: <M2ESetting /> },
        //     { path: "activity-history", element: <ActivityHistory /> },
        //   ],
        // },
        // {
        //   path: "slots",
        //   children: [
        //     { path: "free-slots-settings", element: <FreeSlotsSetting /> },
        //     { path: "settings", element: <SlotsSetting /> },
        //     { path: "history", element: <SlotsHistory /> },
        //   ],
        // },
        {
          path: "airdrop",
          children: [
            { path: "airdrop-nft", element: <AirdropNFT /> },
            // { path: "airdrop-token", element: <AirdropToken /> },
          ],
        },
        {
          path: "admin",
          children: [
            { path: "", element: <INOList /> },
            { path: "administrations", element: <AdminList /> },
            { path: "change-password", element: <ChangePassword /> },
          ],
        },
        // {
        //   path: "ino",
        //   children: [
        //     { path: "rounds", element: <INORound /> },
        //     { path: "products", element: <INOProducts /> },
        //     { path: "history", element: <INOHistory /> },
        //   ],
        // },
        // {
        //   path: "system",
        //   children: [
        //     { path: "contracts", element: <Contracts /> },
        //     { path: "swap", element: <SwapSetting /> },
        //     { path: "upload", element: <Upload /> },
        //     { path: "magic-number", element: <MagicNumber /> },
        //     { path: "whitelist", element: <WhiteList /> },
        //     { path: "exchange-rate", element: <ExchangeRate /> },
        //     { path: "logs", element: <SystemLogs /> },
        //     { path: "withdrawal-configs", element: <WithdrawalConfigs /> },
        //   ],
        // },
        // {
        //   path: "fund",
        //   children: [
        //     { path: "list", element: <FundList /> },
        //     { path: "logs", element: <FundLogs /> },
        //   ],
        // },
        // {
        //   path: "staking",
        //   children: [
        //     { path: "tnt-packages", element: <StakingPackages /> },
        //     { path: "tnt-list", element: <StakingList /> },
        //   ],
        // },
        // {
        //   path: "social-fi",
        //   children: [
        //     { path: "articles", element: <ArticleList /> },
        //     { path: "comments", element: <CommentList /> },
        //     { path: "hashtag", element: <Hashtag /> },
        //     { path: "categories", element: <Categories /> },
        //     { path: "earn-settings", element: <EarnSettingsList /> },
        //     { path: "earn-history", element: <SocialFiEarnHistory /> },
        //     { path: "banners", element: <BannerList /> },
        //     { path: "reports", element: <ReportList /> },
        //   ],
        // },
        { path: "*", element: <ComingSoon /> },
      ],
    },
    {
      path: "/",
      // element:  <Login />,
      element: information ? <Navigate to="/dashboard/overview" /> : <Login />,
    },
    { path: "/login", element: <Navigate to="/login" /> },
    { path: "/404", element: <Page404 /> },
    { path: "/*", element: <Navigate to="/404" replace /> },
  ]);
  return element;
}
