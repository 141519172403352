import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";

const columns = [
  {
    key: "tokenId",
    label: "Token ID",
    isNFTTokenId: true,
  },
  {
    key: "type",
    label: "Type",
    isEnum: true,
    enumKey: "NftTransactionType",
    isStatus: true,
  },
  // {
  //   key: "ownerAddress",
  //   label: "Address",
  //   isAddress: true,
  // },
  {
    key: "fromAddress",
    label: "From Address",
    isAddress: true,
  },
  {
    key: "toAddress",
    label: "To Address",
    isAddress: true,
  },
  // {
  //   key: "txHash",
  //   label: "Tx Hash",
  //   isHash: true,
  // },
  {
    key: "status",
    label: "Status",
    isEnum: true,
    enumKey: "NftTransactionStatus",
    isStatus: true,
  },
  {
    key: "creationDate",
    label: "Created Time",
    isTime: true,
  },
];

const filterBy = [
  new Filter({
    key: "tokenId",
    type: "input",
    text: "Token ID",
  }),
];

const NFTTransactions = (props) =>
  SearchHigherComponent({
    endpoint: EndpointConstant.NFT_GET_TRANSACTION_HISTORY,
    title: "NFT Transaction History",
    columns,
    filterBy,
    ...props,
  })();

export default NFTTransactions;
