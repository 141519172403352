export default function Checkbox(theme) {
  return {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
        },
      },
    },
  };
}
