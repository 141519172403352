import { Box } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BaseOptionChartStyle } from "./components/chart/BaseOptionChart";
import CustomDialog from "./components/CustomDialog";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import { _getAdminProfile } from "./store/admin/adminActions";
import { AdminStoreConstant } from "./store/admin/adminConstants";
import "./styles/Custom.css";
import ThemeProvider from "./theme";
import { isLoggedIn } from "./utils/auth";

export default function App() {
  const dispatch = useDispatch();
  const { admin } = useSelector((state) => state);
  const { informationLoading } = admin;

  useEffect(() => {
    if (isLoggedIn()) {
      dispatch(_getAdminProfile());
    } else {
      dispatch({
        type: AdminStoreConstant.SET_INFORMATION_LOADING,
        payload: false,
      });
    }
    console.log("KT Raon Admin - ver 0.1.4");
  }, [dispatch]);

  return informationLoading ? (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Box
        component={'img'}
        src={'/images/loading.gif'}
        alt=""
        width={'min(100%,250px)'}
      />
    </Box>
  ) : (
    <ThemeProvider>
      <ScrollToTop />
      <BaseOptionChartStyle />
      <Router />
      <ToastContainer />
      <CustomDialog />
    </ThemeProvider>
  );
}
