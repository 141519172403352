export class Person {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.address = obj.address != null ? obj.address : "";
    this.birthDate = obj.birthDate != null ? obj.birthDate : "";
    this.firstName = obj.firstName != null ? obj.firstName : "";
    this.friends = obj.friends != null ? obj.friends : [];
    this.lastName = obj.lastName != null ? obj.lastName : "";
    this.petNames = obj.petNames != null ? obj.petNames : [];
  }
}

export class User {
  constructor(obj) {
    obj = obj != null ? obj : {};
    this.id = obj.id ? obj.id : 0;
    this.creationDate = obj.creationDate
      ? obj.creationDate
      : "2022-12-15T07:25:25.544Z";
    this.creatorId = obj.creatorId ? obj.creatorId : 0;
    this.modificationDate = obj.modificationDate
      ? obj.modificationDate
      : "2022-12-15T07:25:25.544Z";
    this.modificationUserId = obj.modificationUserId
      ? obj.modificationUserId
      : 0;
    this.isDeleted = obj.isDeleted ? obj.isDeleted : true;
    this.email = obj.email ? obj.email : "";
    this.gaEnable = obj.gaEnable ? obj.gaEnable : true;
    this.address = obj.address ? obj.address : "";
    this.displayName = obj.displayName ? obj.displayName : "";
    this.countryCode = obj.countryCode ? obj.countryCode : "";
    this.status = obj.status ? obj.status : 0;
    this.isLockedWithdraw = obj.isLockedWithdraw ? obj.isLockedWithdraw : true;
    this.scopesAs = obj.scopesAs ? obj.scopesAs : "";
    this.skipReferral = obj.skipReferral ? obj.skipReferral : true;
    this.referralId = obj.referralId ? obj.referralId : "";
    this.referralEmail = obj.referralEmail ? obj.referralEmail : "";
    this.memberCount = obj.memberCount ? obj.memberCount : 0;
    this.avatar = obj.avatar ? obj.avatar : "";
    this.firstName = obj.firstName ? obj.firstName : "";
    this.lastName = obj.lastName ? obj.lastName : "";
    this.doB = obj.doB ? obj.doB : "";
    this.gender = obj.gender ? obj.gender : 0;
    this.weight = obj.weight ? obj.weight : 0;
    this.height = obj.height ? obj.height : 0;
    this.character = obj.character ? obj.character : 0;
    this.linkRef = obj.linkRef ? obj.linkRef : "";
    this.systemNote = obj.systemNote ? obj.systemNote : "";
    this.nfteSlot = obj.nfteSlot ? obj.nfteSlot : 0;
    this.availableNfteSlot = obj.availableNfteSlot ? obj.availableNfteSlot : 0;
    this.scopes = obj.scopes ? obj.scopes : [""];
  }
}
