import { EndpointConstant } from "../../constants/endpoint";
import { get } from "../../utils/api";
import { SettingStoreConstant } from "./settingConstants";

export const _switchPopup = (data) => (dispatch) => {
  dispatch({
    type: SettingStoreConstant.SWITCH_POPUP,
    payload: data,
  });
};

export const _getContracts = () => (dispatch) => {
  get(
    EndpointConstant.CONTRACTS,
    (data) => {
      dispatch({
        type: SettingStoreConstant.FETCH_CONTRACTS,
        payload: data,
      });
    },
    (error) => console.error(error)
  );
};

export const _getConfig = () => (dispatch) => {
  get(EndpointConstant.GET_CONFIG, (data) => {
    dispatch({
      type: SettingStoreConstant.FETCH_CONFIG,
      payload: data?.enums,
    });
  });
};

export const _getBCConfiguration = () => (dispatch) => {
  get(EndpointConstant.GET_BC_CONFIGURATION, (data) =>
    dispatch({
      type: SettingStoreConstant.SET_BC_CONFIGURATION,
      payload: data.configuration,
    })
  );
};
