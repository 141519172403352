import { Box, Divider, Drawer, Paper, Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminStoreConstant } from "../store/admin/adminConstants";

export function HTMLConverter(str) {
  str = str.replace(/(<)/gi, "&lt;");
  str = str.replace(/(<)/gi, "&lg;");
  str = str.replace(/#(.+?)(?=[\s.,:,]|$)/g, "<span>#$1</span>");
  str = str.replace(/@(.+?)(?=[\s.,:,]|$)/g, "<span>@$1</span>");
  str = str.replace(
    // eslint-disable-next-line no-useless-escape
    /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/g,
    "<a href='$1' target='_blank' class='linked'>$1</a>"
  );
  str = str.replace(/(?:\r\n|\n\r|\r|\n)/g, "<br />");
  return str;
}

export default function SFPostPreview() {
  const { admin } = useSelector((state) => state);
  const { selectedPost } = admin;
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: AdminStoreConstant.SET_SELECT_POST,
      payload: null,
    });
  };

  return (
    <Drawer
      anchor={"bottom"}
      open={Boolean(selectedPost)}
      onClose={handleClose}
    >
      <Box sx={{ height: 500, p: 2, overflow: "auto" }}>
        {selectedPost && (
          <>
            <Box overflow="auto">
              <Box display="flex" width="max-content">
                {selectedPost?.medias?.map((media, index) => (
                  <Paper
                    sx={{
                      height: 150,
                      width: "auto",
                      mr: 0.5,
                      overflow: "hidden",
                    }}
                    variant="outlined"
                    key={index}
                  >
                    {media.type ? (
                      <video
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: 12,
                          background: "rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <source src={media.url} />
                      </video>
                    ) : (
                      <img
                        src={media.url}
                        alt=""
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "contain",
                          borderRadius: 12,
                          background: "rgba(0, 0, 0, 0.1)",
                        }}
                      />
                    )}
                  </Paper>
                ))}
              </Box>
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box
              position="relative"
              zIndex={0}
              sx={{ cursor: "pointer", wordBreak: "break-all" }}
              textAlign="left"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: HTMLConverter(selectedPost.content),
                }}
                className="text-output"
              />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box>
              Creator: {selectedPost.creator.email} -{" "}
              {selectedPost.creator.address}
            </Box>
            <Divider sx={{ my: 2 }} />
            <Stack flexDirection="row" justifyContent={"space-between"}>
              <p>Likes: {selectedPost.likeNo} </p>
              <p>Comments: {selectedPost.commentNo} </p>
              <p>Shared: {selectedPost.sharedNo} </p>
              <p>Seen: {selectedPost.seenNo} </p>
            </Stack>
          </>
        )}
      </Box>
    </Drawer>
  );
}
