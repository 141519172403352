import { Box, IconButton } from "@mui/material";
import { IconCopy, IconLineDashed } from "@tabler/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AdminStoreConstant } from "../store/admin/adminConstants";

export default function LinkToTokenId({ tokenId }) {
  const dispatch = useDispatch();

  const _onClick = () => {
    dispatch({
      type: AdminStoreConstant.SET_CURRENT_TOKEN_ID,
      payload: tokenId,
    });
  };

  return tokenId ? (
    <Box display="flex" alignItems="center">
      <Box
        sx={{
          color: "#3f51b5",
          cursor: "pointer",
        }}
        onClick={_onClick}
      >
        {tokenId}
      </Box>
      <IconButton color="primary" sx={{ p: 0.5, marginLeft: "2px" }}>
        <IconCopy
          stroke={2}
          style={{ width: 16, height: 16 }}
          onClick={() => {
            toast.success("Copied");
            navigator.clipboard.writeText(tokenId);
          }}
        />
      </IconButton>
    </Box>
  ) : (
    <IconLineDashed stroke={2} style={{ width: 16, height: 16 }} />
  );
}
