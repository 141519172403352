import { Add, SearchOutlined, Sync } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CachedIcon from "@mui/icons-material/Cached";
import PaymentIcon from "@mui/icons-material/Payment";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { LoadingButton } from "@mui/lab";
import {
  AppBar,
  Button,
  Drawer,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { IconFileExport } from "@tabler/icons";
import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { EndpointConstant } from "../constants/endpoint";
import { get, post, put } from "../utils/api";
import ItemField from "./ItemField";
import UploadForCreate from "./UploadForCreated";

function Toolbar({
  createFields,
  filterBy,
  defaultFilter,
  note,
  isProfile,
  createEndpoint,
  syncEndpoint,
  syncMethod,
  _onReload,
  _onReset,
  _onSearch,
  _handleShowCreate,
  _handleExport,
  hasUploadFile,
  componentKey,
  ...props
}) {
  const [filters, setFilters] = useState({});
  const [showToolbar, setShowToolbar] = useState(true);
  const { userId } = useParams();
  const [openSetPaymentRate, setOpenPaymentRate] = useState(false);
  const [openSyncNFT, setOpenSyncNFT] = useState(false);

  const _handleSearch = (e) => {
    e.preventDefault();
    _onSearch(filters);
  };

  const _handleReset = () => {
    setFilters({});
    _onReset();
    setShowToolbar(false);
    setTimeout(() => {
      setShowToolbar(true);
    }, 1);
  };

  const _onChange = (e) => {
    filters[e.target.name] = e.target.value.toString().trim();
    setFilters(filters);
  };

  const _handleSync = () => {
    if (syncMethod === "PUT") {
      put(
        syncEndpoint,
        {},
        () => {
          toast.success("Success");
          _onReload();
        },
        () => toast.error("Fail")
      );
    } else {
      if (syncMethod === "GET") {
        get(
          syncEndpoint,
          () => {
            toast.success("Success");
            _onReload();
          },
          () => toast.error("Fail")
        );
      }
      else
        post(
          syncEndpoint,
          {},
          () => {
            toast.success("Success");
            _onReload();
          },
          () => toast.error("Fail")
        );
    }
  };

  const rightFeatures = [
    {
      label: "Sync NFT",
      icon: <CachedIcon fontSize="small" />,
      disabled: componentKey !== "NFT_LIST",
      onClick: () => setOpenSyncNFT(true),
      show: componentKey === "NFT_LIST",
    },
    {
      label: "Payment Rate",
      icon: <PaymentIcon fontSize="small" />,
      disabled: componentKey !== "CONTRACT",
      onClick: () => setOpenPaymentRate(true),
      show: componentKey === "CONTRACT",
    },
    {
      label: "Sync",
      icon: <Sync fontSize="small" />,
      disabled: !syncEndpoint,
      onClick: _handleSync,
      show: true,
    },
    {
      label: "Add",
      icon: <Add fontSize="small" />,
      disabled: !Boolean(createEndpoint),
      onClick: _handleShowCreate,
      show: true,
    },
    {
      component: (
        <UploadForCreate
          createFields={createFields}
          createEndpoint={createEndpoint}
          disabled={!hasUploadFile}
        />
      ),
    },
    {
      label: "Export",
      icon: (
        <IconFileExport style={{ width: 20, height: 20, marginRight: "5px" }} />
      ),
      onClick: _handleExport,
      show: true,
    },
  ];

  return (
    <>
      <form onSubmit={_handleSearch} id="filter-form">
        <Grid container spacing={2}>
          {filterBy &&
            (showToolbar ? (
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  {filterBy.map((item, index) => {
                    if (
                      (((userId && note !== "referrals") || isProfile) &&
                        (item.key === "userId" ||
                          item.key === "username" ||
                          item.key === "email" ||
                          item.key === "wallet" ||
                          item.key === "address" ||
                          item.key === "ownerAddress")) ||
                      (item.key === "tokenId" && props.tokenId)
                    ) {
                      return null;
                    } else {
                      return (
                        <ItemField
                          data={item}
                          key={index}
                          onChange={_onChange}
                          filters={filters}
                          defaultFilter={defaultFilter}
                        />
                      );
                    }
                  })}
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                style={{
                  height: Math.ceil(filterBy.length / 6) * 56,
                }}
              ></Grid>
            ))}
          <Grid item xs={12} p={0}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                {filterBy && (
                  <>
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                        >
                          <SearchOutlined fontSize="small" /> Search
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="button"
                          onClick={_onReload}
                          variant="outlined"
                          color="secondary"
                        >
                          <CachedIcon fontSize="small" /> Refresh
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          type="button"
                          onClick={_handleReset}
                          variant="outlined"
                          color="secondary"
                        >
                          <RotateLeftIcon fontSize="small" /> Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item>
                <Grid container spacing={1}>
                  {rightFeatures.map((f, index) =>
                    f.component ? (
                      <Grid item key={index}>
                        {f.component}
                      </Grid>
                    ) : (
                      f.show && (
                        <Grid item key={index}>
                          <Button
                            onClick={f.onClick}
                            disabled={f.disabled}
                            color={
                              index + 1 === rightFeatures.length
                                ? "primary"
                                : "secondary"
                            }
                            variant={
                              index + 1 === rightFeatures.length
                                ? "contained"
                                : "outlined"
                            }
                          >
                            {f.icon}
                            {f.label}
                          </Button>
                        </Grid>
                      )
                    )
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <PaymentRate
        open={openSetPaymentRate}
        _onClose={() => setOpenPaymentRate(false)}
        _onReload={_onReload}
      />
      <SyncNFT
        open={openSyncNFT}
        _onClose={() => setOpenSyncNFT(false)}
        _onReload={_onReload}
      />
    </>
  );
}

export default Toolbar;

const PaymentRate = ({ open, _onClose, _onReload }) => {
  const [loading, setLoading] = useState(false);

  const _handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const contractAddress = e.target.contractAddress.value;
    const paymentTokenUSD = e.target.paymentTokenUSD.value;
    post(
      EndpointConstant.CONTRACT_PAYMENT_RATE,
      {
        contractAddress,
        paymentTokenUSD,
      },
      () => {
        toast.success("Success");
        setLoading(false);
        _onClose();
        _onReload();
      },
      () => {
        toast.error("Fail");
        setLoading(false);
      }
    );
  };

  return (
    <Drawer anchor="right" open={open} onClose={_onClose}>
      <Box width={500}>
        <AppBar position="sticky" sx={{ py: 1 }}>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={_onClose} disabled={loading}>
                <ArrowBackIcon fontSize="small" sx={{ fill: "#fff" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1">Back</Typography>
            </Grid>
          </Grid>
        </AppBar>
        <Box component="form" p={2} onSubmit={_handleSubmit}>
          <Typography variant="h6">Payment rate setting</Typography>
          <Box mt={2} />
          <ItemField
            data={{
              type: "select",
              selectName: "PAYMENT_CONTRACTS",
              text: "Select payment contract",
              key: "contractAddress",
              required: true,
              col: 12,
            }}
          />
          <Box mt={2} />
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="USDT rate"
            id="paymentTokenUSD"
            name="paymentTokenUSD"
            required
          />
          <Box mt={2} />
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

const SyncNFT = ({ open, _onClose, _onReload }) => {
  const [loading, setLoading] = useState(false);

  const _handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const tokenId = e.target.tokenId.value;
    post(
      EndpointConstant.SYNC_NFT,
      {
        tokenId,
      },
      () => {
        toast.success("Success");
        setLoading(false);
        _onClose();
        _onReload();
      },
      () => {
        toast.error("Fail");
        setLoading(false);
      }
    );
  };

  return (
    <Drawer anchor="right" open={open} onClose={_onClose}>
      <Box width={500}>
        <AppBar position="sticky" sx={{ py: 1 }}>
          <Grid container alignItems="center">
            <Grid item>
              <IconButton onClick={_onClose} disabled={loading}>
                <ArrowBackIcon fontSize="small" sx={{ fill: "#fff" }} />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="body1">Back</Typography>
            </Grid>
          </Grid>
        </AppBar>
        <Box component="form" p={2} onSubmit={_handleSubmit}>
          <Typography variant="h6">Sync NFT from Blockchain</Typography>
          <Box mt={2} />
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            label="NFT Token ID"
            id="tokenId"
            name="tokenId"
            required
          />
          <Box mt={2} />
          <LoadingButton
            fullWidth
            variant="contained"
            type="submit"
            loading={loading}
          >
            Submit
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};
