import { IconButton } from "@mui/material";
import { IconCopy, IconLineDashed } from "@tabler/icons";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getLinkHash } from "../settings/format";

export default function LinkToHash({ txHash }) {
  const { setting } = useSelector((state) => state);
  const { bcConfiguration } = setting;

  return txHash ? (
    <>
      {getLinkHash(
        { txHash },
        bcConfiguration?.networkConfig?.info?.blockExplorerUrls[0]
      )}
      <IconButton color="primary" sx={{ p: 0.5, marginLeft: "2px" }}>
        <IconCopy
          stroke={2}
          style={{ width: 16, height: 16 }}
          onClick={() => {
            toast.success("Copied");
            navigator.clipboard.writeText(txHash);
          }}
        />
      </IconButton>
    </>
  ) : (
    <IconLineDashed />
  );
}
