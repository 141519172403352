import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Drawer, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EndpointConstant } from "../constants/endpoint";
import CustomLoading from "../HOC/CustomLoading";
import NFTTransactions from "../pages/nft/NFTTransactions";
import { AdminStoreConstant } from "../store/admin/adminConstants";
import { post } from "../utils/api";

const information = [
  {
    key: "tokenId",
    label: "Token ID",
  },
  {
    key: "ownerAddress",
    label: "Owner address",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "mintTxHash",
    label: "Mint Tx hash",
  },
  {
    key: "burnTxHash",
    label: "Burn tx hash",
  },
  {
    key: "creationDate",
    label: "Creation date",
  },
  {
    key: "modificationDate",
    label: "Modification date",
  },
];

export default function CurrentToken() {
  const { admin } = useSelector((state) => state);
  const { currentTokenId } = admin;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);

  useEffect(() => {
    if (currentTokenId) {
      post(
        EndpointConstant.NFT_GET_LIST,
        {
          page: 1,
          pageSize: 1,
          responseMeta: false,
          filters: {
            tokenId: currentTokenId.toString(),
          },
        },
        (data) => {
          const { itemCount, items } = data;
          if (itemCount > 0) {
            setData(items[0]);
          } else {
            setData("UNKNOWN");
          }
        }
      );
    } else {
      setData(null);
    }
  }, [currentTokenId]);

  const _onClose = () => {
    dispatch({
      type: AdminStoreConstant.SET_CURRENT_TOKEN_ID,
      payload: null,
    });
  };

  return (
    <Drawer anchor="right" open={currentTokenId !== null} onClose={_onClose}>
      <Box width="70vw" p={3}>
        {data ? (
          data === "UNKNOWN" ? (
            <Typography>Unknown Token</Typography>
          ) : (
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12}>
                <Button variant="contained" onClick={_onClose}>
                  <ArrowBackIos fontSize="small" />
                  Back
                </Button>
              </Grid>
              <Grid item xs={2}>
                <img src={data.info.image} alt="" />
              </Grid>
              <Grid item xs={10}>
                {information.map((i, index) => (
                  <Typography key={index}>
                    {i.label}: {data[i.key]}
                  </Typography>
                ))}
              </Grid>
              <Grid item xs={12}>
                {data && <NFTTransactions tokenId={data.tokenId.toString()} />}
              </Grid>
            </Grid>
          )
        ) : (
          <CustomLoading />
        )}
      </Box>
    </Drawer>
  );
}
