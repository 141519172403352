import { Box, CircularProgress } from "@mui/material";
import React from "react";

export default function CustomLoading() {
  return (
    <Box textAlign={"center"}>
      <CircularProgress size={20} />
    </Box>
  );
}
