import { GET_PROFILE } from '../constants/reducerConstant';

const initialState = {
  profile: null,
};

// eslint-disable-next-line default-param-last
function UserReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GET_PROFILE:
      return { ...state, profile: payload };
    default:
      return { ...state };
  }
}

export default UserReducer;
