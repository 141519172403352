export const IdentityEndpoint = {
  ADMIN_LOGIN: `/identity/admin/login`,
  ADMIN_CHECK_GA: `/identity/admin/ga/check`,
  ADMIN_GA: `/identity/admin/ga`,
};

export const EndpointConstant = {
  INO_ROUND_LIST: `/nft/admin/ino/rounds/get-list`,
  INO_ROUND_ADD: `/nft/admin/ino/rounds/add`,
  INO_ROUND_UPDATE: `/nft/admin/ino/rounds/update`,
  INO_ROUND_SYNC: `/nft/admin/ino/rounds/sync`,
  INO_LIST: `/nft/admin/ino/get-list`,
  INO_ADD: `/nft/admin/ino/add`,
  INO_UPDATE: `/nft/admin/ino/update`,
  INO_TRANSACTIONS: `/nft/admin/ino/transactions/get-list`,
  CONTRACTS: `/nft/admin/contract/get-list`,
  CONTRACT_ADD: `/nft/admin/contract/add`,
  CONTRACT_UPDATE: `/nft/admin/contract/update`,
  UPLOAD_FILES: `/nft/admin/files/get-list`,
  UPLOAD: `/nft/admin/files/upload`,
  GET_CONFIG: `/system/enums`,
  NFT_CONTRACT_SYNC: `/nft/admin/contract/sync`,
  NFT_MAGIC_NUMBER: `/nft/admin/magic-number/get-list`,
  NFT_MAGIC_NUMBER_ADD: `/nft/admin/magic-number/add`,
  NFT_MAGIC_NUMBER_UPDATE: `/nft/admin/magic-number/update`,
  NFT_AIRDROP_NFT: `/nft/admin/air-drop-nft/get-list`,
  NFT_AIRDROP_NFT_ADD: `/nft/admin/air-drop-nft/add`,
  NFT_AIRDROP_TOKEN: `/nft/admin/air-drop-token/get-list`,
  NFT_AIRDROP_TOKEN_ADD: `/nft/admin/air-drop-token/add`,
  NFT_GET_LIST: `/nft/admin/nfts/get-list`,
  MALL_POINT_GET_LIST: `/nft/admin/nfts/get-sells`,
  SWAP_GET_LIST: `/nft/admin/swap-history`,
  SYNC_JOB_CHECK_SWAP: `/nft/admin/sync-job-check-swap`,
  NFT_GET_TRANSACTION_HISTORY: `/nft/admin/nft-transaction/get-list`,
  ADMIN_LIST: `/identity/admin/get-list`,
  ADMIN_CHANGE_PASSWORD: `/identity/admin/change-password`,
  CONTRACT_PAYMENT_RATE: `/nft/admin/contract/payment-rate`,
  SWAP_CONFIG_LIST: `/nft/admin/fund-swap-config/get-list`,
  SWAP_CONFIG_ADD: `/nft/admin/fund-swap-config/add`,
  SWAP_CONFIG_UPDATE: `/nft/admin/fund-swap-config/update`,
  FUND_LIST: `/nft/admin/funds/get-list`,
  FUND_LOGS: `/nft/admin/fund-logs/get-list`,
  USER_BAN: `/identity/admin/ban-user`,

  FREE_SLOTS_SETTINGS: `/identity/admin/free-slot-settings`,
  FREE_SLOTS_SETTINGS_ADD: `/identity/admin/free-slot-setting/add`,
  FREE_SLOTS_SETTINGS_UPDATE: `/identity/admin/free-slot-setting/update`,

  FUSION_SETTINGS: `/nft/admin/fusion/settings`,
  FUSION_SETTING_ADD: `/nft/admin/fusion/add-setting`,
  FUSION_SETTING_UPDATE: `/nft/admin/fusion/update-setting`,
  FUSION_SETTING_DELETE: `/nft/admin/fusion/delete-setting`,

  SLOTS_SETTINGS: `/run/admin/slot-settings`,
  SLOTS_SETTINGS_ADD: `/run/admin/slot-setting/add`,
  SLOTS_SETTINGS_UPDATE: `/run/admin/slot-setting/update`,
  SLOTS_HISTORIES: `/run/admin/slot-histories`,

  M2E_SETTINGS: `/run/admin/m2e-settings`,
  M2E_SETTING_ADD: `/run/admin/m2e-setting/add`,
  M2E_SETTING_UPDATE: `/run/admin/m2e-setting/update`,
  M2E_HISTORIES: `/run/admin/run-activity-histories`,

  FUSION_HISTORY: `/nft/admin/fusion/histories`,
  FUSION_RANKINGS: `/nft/admin/fusion/rankings`,
  FUSION_RANKING_ADD: `/nft/admin/fusion/add-ranking`,

  WHITELIST_GET_LIST: `/nft/admin/ino/white-list/get-list`,
  WHITELIST_ADD: `/nft/admin/ino/white-list/add`,
  WHITELIST_UPDATE: `/nft/admin/ino/white-list/update`,

  GET_BC_CONFIGURATION: `/nft/configuration`,
  UNLOCK_NFT: `/nft/admin/nfts/un-lock`,
  LOCK_NFT: `/nft/admin/nfts/lock`,
  ReMintingNFT: `/nft/admin/nfts/re-mint`,
  EXCHANGE_RATE_GET_LIST: `/nft/admin/exchange-rate/get-list`,
  EXCHANGE_RATE_ADD: `/nft/admin/exchange-rate/add`,
  EXCHANGE_RATE_UPDATE: `/nft/admin/exchange-rate/update`,
  SYNC_NFT: `/nft/admin/nfts/sync`,
  SystemLogs: `/admin/log/request/get`,
  WithdrawalConfigs: `/nft/admin/with-draw/configuration`,
  BannerGetList: `/posts/users/banners/get-list`,
  BannerAdd: `/posts/admin/banners/add`,
  BannerUpdate: `/posts/admin/banners/update`,

  //social-fi
  HashtagGetList: `/posts/admin/hashtag/get-list`,
  HashtagAdd: `/posts/admin/hashtag/add`,
  HashtagUpdate: `/posts/admin/hashtag/update`,
  CategoryGetList: `/posts/admin/category/get-list`,
  CategoryAdd: `/posts/admin/category/add`,
  CategoryUpdate: `/posts/admin/category/update`,
  ArticleList: `/posts/admin/post/get-list`,
  CommentList: `/posts/admin/comments/get-list`,
  RemoveComment: `/posts/admin/comments/remove`,
  EarnSettingsList: `/posts/admin/post/configuration`,
  EarnSettingsUpdate: `/posts/admin/post/configuration`,
  RemoveArticle: `/posts/admin/post/delete`,
  SFEarnHistory: `/posts/admin/post/earn/get-list`,
  ReportList: `/posts/admin/post/reports/get-list`,

  //staking
  StakingPackageList: `/admin/staking/package/list`,
  StakingPackageAdd: `/admin/staking/package/add`,
  StakingPackageUpdate: `/admin/staking/package/update`,
  StakingList: `/admin/staking/list`,
  StakingList_SYNC: `/admin/staking/sync-profit`,
  StakingNFTEPackageList: `/admin/staking/nfte/package/list`,
  StakingNFTEPackageAdd: `/admin/staking/nfte/package/add`,
  StakingNFTEPackageUpdate: `/admin/staking/nfte/package/update`,
  StakingNFTEList: `/admin/staking/nfte/list`,
  StakingNFTEList_SYNC: `/admin/staking/nfte/sync-profit`,

  //new staking
  NewStakingPackageList: `/admin/staking/v2/package/list`,
  NewStakingPackageAdd: `/admin/staking/v2/package/add`,
  NewStakingPackageUpdate: `/admin/staking/v2/package/update`,
  NewStakingList: `/admin/staking/v2/list`,
  NewStakingList_SYNC: `/admin/staking/v2/sync-profit`,

  ApproveWithdraw: `/nft/admin/funds/approve`,
  CancelWithdraw: `/nft/admin/funds/cancel`,
};
