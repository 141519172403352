import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";

const columns = [
  {
    key: "tokenId",
    label: "Token ID",
    isNFTTokenId: true,
  },
  {
    key: "ownerAddress",
    label: "Address",
    isOwnerAddress: true,
  },
  {
    key: "inDb",
    label: "System Type",
    isNFT: true,
  },
  {
    key: "type",
    label: "Type",
    isEnum: true,
    enumKey: "NftType",
  },
  {
    key: "level",
    label: "Level",
    isEnum: true,
    enumKey: "NftLevel",
  },
  {
    key: "status",
    label: "Status",
    isEnum: true,
    enumKey: "NftStatus",
    isStatus: true,
  },
  {
    key: "mintTxHash",
    label: "Mint Hash",
    isHash: true,
  },
  {
    key: "burnTxHash",
    label: "Burn Hash",
    isHash: true,
  },
  {
    key: "lockTxHash",
    label: "Lock Hash",
    isHash: true,
  },

  {
    key: "creationDate",
    label: "Created Time",
    isTime: true,
  },
  {
    key: "modificationDate",
    label: "Modification Time",
    isTime: true,
  },
];

const filterBy = [
  // new Filter({
  //   key: "fromDate",
  //   type: "date-time",
  //   text: "From date",
  //   col: 3,
  // }),
  // new Filter({
  //   key: "toDate",
  //   type: "date-time",
  //   text: "To date",
  //   col: 3,
  // }),
  new Filter({
    key: "tokenId",
    type: "input",
    text: "Token ID",
  }),
  new Filter({
    key: "ownerAddress",
    type: "input",
    text: "Owner address",
  }),
  new Filter({
    key: "level",
    type: "select",
    text: "Level",
    isEnum: true,
    enumKey: "NftLevel",
  }),
  new Filter({
    key: "status",
    type: "select",
    text: "Status",
    isEnum: true,
    enumKey: "NftStatus",
  }),
];

const NFTList = (props) =>
  SearchHigherComponent({
    componentKey: "NFT_LIST",
    endpoint: EndpointConstant.NFT_GET_LIST,
    title: "NFT list",
    columns,
    filterBy,
    hasUnlockNFT: true,
    hasReMint: true,
    ...props,
  })();

export default NFTList;
