import { IconButton, Link } from "@mui/material";
import { toast } from "react-toastify";
import { IconCopy, IconLineDashed } from "@tabler/icons";
import Label from "../components/Label";

function formatBigMoney(str) {
  const num = Math.abs(Number(str));
  let result;
  if (num >= 1.0e9) {
    // Nine Zeroes for Billions
    const decimal = Math.floor((num - Math.floor(num / 1.0e9) * 1.0e9) / 1.0e7);
    result =
      Math.floor(num / 1.0e9) +
      "." +
      (decimal > 9 ? decimal : "0" + decimal) +
      "B";
  } else {
    if (num >= 1.0e6) {
      // Six Zeroes for Millions
      const decimal = Math.floor(
        (num - Math.floor(num / 1.0e6) * 1.0e6) / 1.0e4
      );
      result =
        Math.floor(num / 1.0e6) +
        "." +
        (decimal > 9 ? `${decimal}` : `0${decimal}`) +
        "M";
    } else {
      if (num >= 1.0e3) {
        // Three Zeroes for Thousands
        result = Math.floor(num / 1.0e3) + "K";
      }
    }
  }
  return result;
}
// module.exports = {
//   formatAmountInput: (str) => {
//     str += "";
//     const deleteText = str.replace(/[^\d.]/g, ""); //clear text
//     const x = deleteText.split(".");
//     let x1 = x[0];
//     const x2 = x[1];
//     const x3 = x.length > 1 ? "." + x2.slice(0, 8) : "";
//     if (!x1) x1 = "0";
//     let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
//     return result;
//   },
//   formatMoney: (str) => {
//     str += "";
//     const deleteText = str.replace(/[^\d.]/g, ""); //clear text
//     const x = deleteText.split(".");
//     let x1 = x[0];
//     const x2 = x[1];
//     const x3 = x.length > 1 ? "." + x2.slice(0, 2) : "";
//     if (!x1) x1 = "0";
//     const rgx = /(\d+)(\d{3})/;
//     while (rgx.test(x1)) {
//       x1 = x1.replace(rgx, "$1,$2");
//     }
//     let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
//     return result;
//   },
//   deleteText: (str) => {
//     str += "";
//     const deleteText = str.replace(/[^\d.]/g, ""); //clear text
//     return deleteText;
//   },
//   formatDate: (time) => {
//     const temp = new Date(time);
//     var dateString =
//       temp.getFullYear() +
//       "-" +
//       ("0" + (temp.getMonth() + 1)).slice(-2) +
//       "-" +
//       ("0" + temp.getDate()).slice(-2);
//     return dateString;
//   },
//   formatTime: (time) => {
//     const temp = new Date(time);
//     var dateString =
//       temp.getFullYear() +
//       "-" +
//       ("0" + (temp.getMonth() + 1)).slice(-2) +
//       "-" +
//       ("0" + temp.getDate()).slice(-2) +
//       " " +
//       ("0" + temp.getHours()).slice(-2) +
//       ":" +
//       ("0" + temp.getMinutes()).slice(-2) +
//       ":" +
//       ("0" + temp.getSeconds()).slice(-2);
//     return dateString;
//   },
// };

export function formatAddress_1(string, length = 10) {
  if (string)
    return (
      <>
        {string.length > length * 2
          ? string.slice(0, length) +
            "..." +
            string.slice(string.length - length)
          : string}
      </>
    );
  else return <IconLineDashed stroke={2} style={{ width: 16, height: 16 }} />;
}
export function formatAddress(string, length = 10) {
  if (string)
    return (
      <>
        {string.length > length * 2
          ? string.slice(0, length) +
            "..." +
            string.slice(string.length - length)
          : string}
      </>
    );
  else
    return (
      <>
        <IconLineDashed stroke={2} style={{ width: 16, height: 16 }} />
      </>
    );
}

export function getLinkHash(data, scanLink = "") {
  if (data.txHash) {
    const { txHash } = data;
    let address = `${scanLink}/tx/${txHash}`;
    return (
      <>
        <Link href={address} target="_blank" rel="noreferrer">
          {formatAddress(txHash)}
        </Link>
      </>
    );
  } else {
    return (
      <>
        <IconLineDashed />
      </>
    );
  }
}

export function formatShortAmount(str) {
  str += "";
  let deleteText = str.replace(/[^\d.]/g, ""); //clear text
  deleteText = Math.floor(deleteText * 1.0e8) / 1.0e8;
  const x = deleteText.toString().split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, 8) : "";
  if (!x1) x1 = "0";
  if (x1.length > 6) {
    return formatBigMoney(x1);
  }
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
}

// export function formatAmount(str) {
//   str += "";
//   let deleteText = str.replace(/[^\d.]/g, ""); //clear text
//   deleteText = Math.floor(deleteText * 1.0e8) / 1.0e8;
//   const x = deleteText.toString().split(".");
//   let x1 = x[0];
//   const x2 = x[1];
//   const x3 = x.length > 1 ? "." + x2.slice(0, 8) : "";
//   if (!x1) x1 = "0";
//   const rgx = /(\d+)(\d{3})/;
//   while (rgx.test(x1)) {
//     x1 = x1.replace(rgx, "$1,$2");
//   }
//   let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
//   return result;
// }

export function formatAmount(str) {
  let deleteText = Number(str)
    .toFixed(8)
    .replace(/\.?0+$/, "");
  deleteText = deleteText !== "NaN" ? deleteText : 0;
  const x = deleteText.toString().split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, 8) : "";
  if (!x1) x1 = "0";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
}

export function formatShortUSD(str) {
  str += "";
  const deleteText = str.replace(/[^\d.]/g, ""); //clear text
  const x = deleteText.split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, 2) : "";
  if (!x1) x1 = "0";
  if (x1.length > 6) {
    return formatBigMoney(x1);
  }
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
}

export function formatNumber(str, length = 8) {
  let deleteText = Number(str)
    .toFixed(8)
    .replace(/\.?0+$/, "");
  deleteText = deleteText !== "NaN" ? deleteText : 0;
  const x = deleteText.toString().split(".");
  let x1 = x[0];
  const x2 = x[1];
  const x3 = x.length > 1 ? "." + x2.slice(0, length) : "";
  if (!x1) x1 = "0";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  let result = (x1 + x3).replace(/^0+(?!\.|$)/, "").replace(/^\./, "");
  return result;
}

export function fStatus(status, msg) {
  const successComp = <Label color="success">{msg || status}</Label>;
  const warningComp = <Label color="warning">{msg || status}</Label>;
  const errorComp = <Label color="error">{msg || status}</Label>;

  switch (status) {
    case true:
    case "ACTIVE":
    case "SUCCESS":
    case "COMPLETED":
      return successComp;
    case "REPAIRING":
    case "PENDING":
      return warningComp;
    case false:
    case "INACTIVE":
      return errorComp;
    default:
      return <Label>{msg || status}</Label>;
  }
}

export function _convertCsvToArray(str, delimiter = ",") {
  const headers = str.slice(0, str.indexOf("\n")).trim().split(delimiter);
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");
  const arr = rows.map((row) => {
    const values = row.replace("\r", "").split(delimiter);
    const el = headers.reduce((object, header, index) => {
      object[header] = values[index];
      return object;
    }, {});
    return el;
  });
  return arr;
}
