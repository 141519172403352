export function Filter({
  key,
  type,
  text,
  defaultValue,
  list,
  col,
  require,
  disabled,
  selectName,
  specialFields,
  isEnum,
  enumKey,
  checkEditable,
  numberOfRecords,
}) {
  this.key = key;
  this.type = type;
  this.text = text;
  this.list = list;
  this.col = col;
  this.require = require;
  this.defaultValue = defaultValue;
  this.disabled = disabled;
  this.selectName = selectName;
  this.specialFields = specialFields;
  this.isEnum = isEnum;
  this.enumKey = enumKey;
  this.checkEditable = checkEditable;
  this.numberOfRecords = numberOfRecords;
}
