export default function Backdrop() {
  return {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          background: 'rgba(0, 0, 0, 0.4)',
          backdropFilter: 'blur(5px)',
          '&.MuiBackdrop-invisible': {
            background: 'transparent',
            backdropFilter: 'none',
          },
        },
      },
    },
  };
}
