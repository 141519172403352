import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";

const columns = [
  {
    key: "id",
    label: "",
    isId: true,
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "address",
    label: "Address",
    isUserAddress: true,
  },
  // {
  //   key: "inDb",
  //   label: "In Database",
  //   isUsed: true,
  // },
  {
    key: "creationDate",
    label: "Created Time",
    isTime: true,
  },
];

const createFields = [
  new Filter({
    key: "name",
    type: "input",
    text: "Name",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "address",
    type: "input",
    text: "Address",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "paymentContract",
    type: "select",
    text: "Payment Contract",
    col: 12,
    selectName: "PAYMENT_CONTRACTS",
  }),
  // new Filter({
  //   key: "inDb",
  //   type: "singleCheckbox",
  //   text: "In DB",
  //   col: 12,
  // }),
  new Filter({
    key: "items",
    type: "special",
    text: "Items",
    col: 12,
    specialFields: [
      new Filter({
        key: "type",
        type: "select",
        text: "Type",
        col: 3,
        require: true,
        isEnum: true,
        enumKey: "NftType",
      }),
      new Filter({
        key: "level",
        type: "select",
        text: "Level",
        col: 3,
        isEnum: true,
        enumKey: "NftLevel",
      }),
      new Filter({
        key: "amount",
        type: "input",
        text: "Amount",
        col: 3,
        require: true,
        isEnum: true,
      }),
      new Filter({
        key: "price",
        type: "input",
        text: "Price",
        col: 3,
        isEnum: true,
      }),
    ],
  }),
];

export default SearchHigherComponent({
  endpoint: EndpointConstant.NFT_AIRDROP_NFT,
  title: "Airdrop NFT",
  createEndpoint: EndpointConstant.NFT_AIRDROP_NFT_ADD,
  columns,
  createFields,
  hasUploadFile: true,
});
