import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";
import { fAddress } from "../../utils/format";
const providers = [
  'AirTNT',
  'Stepwatch',
  'Infinity Games'
]
const columns = [
  {
    key: "transformNft.ownerAddress",
    label: "Owner Address",
    render: (data) => <>{data.transformNft.ownerAddress}</>
  },
  {
    key: "transformNft.tokenId",
    label: "Raon ID",
    render: (data) => <>{data.transformNft.tokenId}</>
  },
  {
    key: "transformNft.raonToProvider",
    label: "Swap",
    render: (data) => <>{data.raonToProvider ? 'Go' : 'Back'}</>
  },
  {
    key: "type",
    label: "Type",
    render: (data) => <>{data.type ? 'Swap' : 'Generate'}</>
  },
  {
    key: "transformNft.provider",
    label: "Provider",
    render: (data) => <>{providers[data.transformNft.provider]}</>
  },
  {
    key: "transformNft.tokenId",
    label: "Provider ID",
    render: (data) => <>{data.transformNft.transformNftToken}</>
  },
  {
    key: "creationDate",
    label: "Created Time",
    isTime: true,
  },
];

const filterBy = [
  new Filter({
    key: "nftTokenId",
    type: "input",
    text: "Nft TokenId",
  }),
  new Filter({
    key: "nftTokenIdTransform",
    type: "input",
    text: "Provider Id",
  }),
  new Filter({
    key: "ownerAddress",
    type: "input",
    text: "Owner Address",
  }),

];

const SwapList = (props) =>
  SearchHigherComponent({
    endpoint: EndpointConstant.SWAP_GET_LIST,
    title: "Swap List",
    columns,
    filterBy,
    ...props,
  })();

export default SwapList;
