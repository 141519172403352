import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";

const INOProductColumns = [
  {
    key: "id",
    label: "Product ID",
    isId: true,
  },
  {
    key: "roundNumber",
    label: "Round",
  },
  {
    key: "unitPrice",
    label: "Price (USDT)",
  },
  {
    key: "type",
    label: "NFT Type",
    isEnum: true,
    enumKey: "NftType",
  },
  {
    key: "level",
    label: "NFT Level",
    isEnum: true,
    enumKey: "NftLevel",
  },
  {
    key: "supply",
    label: "Total Sell",
    isAmount: true,
  },
  {
    key: "available",
    label: "Available",
    isAmount: true,
  },
  {
    key: "items",
    label: "Sold",
    isSold: true,
  },
  {
    key: "isActive",
    label: "Status",
    isBool: true,
  },
];

const INOProductCreateFields = [
  new Filter({
    key: "roundNumber",
    type: "select",
    text: "Round",
    col: 12,
    require: true,
    selectName: "MINTING_ROUND",
  }),
  new Filter({
    key: "type",
    type: "select",
    text: "NFT Type",
    col: 12,
    require: true,
    isEnum: true,
    enumKey: "NftType",
  }),
  new Filter({
    key: "level",
    type: "select",
    text: "NFT Level",
    col: 12,
    require: true,
    isEnum: true,
    enumKey: "NftLevel",
  }),
  new Filter({
    key: "name",
    type: "input",
    text: "Name",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "unitPrice",
    type: "input",
    text: "Price (USDT)",
    col: 12,
    require: true,
    isEnum: true,
  }),
  new Filter({
    key: "discountAmountPerNFT",
    type: "input",
    text: "Discount Amount Per NFT",
    col: 12,
    require: true,
    isEnum: true,
  }),
  new Filter({
    key: "supply",
    type: "input",
    text: "Total Sell",
    col: 12,
    require: true,
    isEnum: true,
  }),
  new Filter({
    key: "available",
    type: "input",
    text: "Available",
    col: 12,
    require: true,
    isEnum: true,
  }),
  new Filter({
    key: "minOrder",
    type: "input",
    text: "Min",
    col: 6,
    require: true,
    isEnum: true,
  }),
  new Filter({
    key: "maxOrder",
    type: "input",
    text: "Max",
    col: 6,
    require: true,
    isEnum: true,
  }),
  new Filter({
    key: "isActive",
    type: "singleCheckbox",
    text: "Active",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "items",
    type: "special",
    text: "Items",
    col: 12,
    specialFields: [
      new Filter({
        key: "paymentContract",
        type: "select",
        selectName: "PAYMENT_CONTRACTS",
        text: "Payment Contract",
        col: 8,
        require: true,
      }),
      new Filter({
        key: "isDeleted",
        type: "singleCheckbox",
        text: "Deleted",
        col: 4,
        require: true,
        isEnum: true,
      }),
    ],
  }),
];

const INORoundColumns = [
  {
    key: "roundNumber",
    label: "Round",
  },
  {
    key: "name",
    label: "Name",
  },
  {
    key: "startTime",
    label: "Start Time",
    isTime: true,
  },
  {
    key: "endTime",
    label: "End Time",
    isTime: true,
  },
  {
    key: "isActive",
    label: "Status",
    isBool: true,
  },
  {
    key: "isDeleted",
    label: "Deleted",
    isUsed: true,
  },
  {
    key: "useWhitelist",
    label: "Use Whitelist",
    isUsed: true,
  },
];

const INORoundCreatedFields = [
  new Filter({
    key: "roundNumber",
    type: "select",
    text: "Round",
    col: 12,
    require: true,
    selectName: "MINTING_ROUND",
  }),
  new Filter({
    key: "name",
    type: "input",
    text: "Name",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "startTime",
    type: "date-time",
    text: "Start",
    col: 6,
    require: true,
  }),
  new Filter({
    key: "endTime",
    type: "date-time",
    text: "End",
    col: 6,
    require: true,
  }),
  new Filter({
    key: "isActive",
    type: "singleCheckbox",
    text: "Active",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "isDeleted",
    type: "singleCheckbox",
    text: "Deleted",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "useWhitelist",
    type: "singleCheckbox",
    text: "Use Whitelist",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "vesting",
    type: "object",
    text: "Vesting Config",
    col: 12,
    specialFields: [
      new Filter({
        type: "select",
        selectName: "PAYMENT_CONTRACTS",
        text: "Select vesting token",
        key: "paymentContract",
        col: 6,
      }),
      new Filter({
        key: "tgeTime",
        type: "date-time",
        text: "TGE Time",
        col: 6,
        require: true,
      }),
      new Filter({
        key: "tgeUnlockPercent",
        type: "input",
        text: "TGE Unlock Percent",
        col: 4,
        require: true,
        isEnum: true,
      }),
      new Filter({
        key: "cliffMonths",
        type: "input",
        text: "Cliff Months",
        col: 4,
        require: true,
        isEnum: true,
      }),
      new Filter({
        key: "number0fLinearMonth",
        type: "input",
        text: "Number 0f Linear Month",
        col: 4,
        require: true,
        isEnum: true,
      }),
    ],
  }),
];

const INORoundUpdateFields = [
  new Filter({
    key: "roundNumber",
    type: "select",
    text: "Round",
    col: 12,
    require: true,
    selectName: "MINTING_ROUND",
  }),
  new Filter({
    key: "name",
    type: "input",
    text: "Name",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "startTime",
    type: "date-time",
    text: "Start",
    col: 6,
    require: true,
  }),
  new Filter({
    key: "endTime",
    type: "date-time",
    text: "End",
    col: 6,
    require: true,
  }),
  new Filter({
    key: "isActive",
    type: "singleCheckbox",
    text: "Active",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "isDeleted",
    type: "singleCheckbox",
    text: "Deleted",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "useWhitelist",
    type: "singleCheckbox",
    text: "Use Whitelist",
    col: 12,
    require: true,
  }),
  new Filter({
    key: "profits",
    type: "special",
    text: "Profits",
    col: 12,
    specialFields: [
      new Filter({
        key: "receiver",
        type: "input",
        text: "Receiver",
        col: 9,
        require: true,
      }),
      new Filter({
        key: "percentage",
        type: "input",
        text: "Percentage",
        col: 3,
        require: true,
        isEnum: true,
      }),
    ],
  }),
  new Filter({
    key: "vesting",
    type: "object",
    text: "Vesting Config",
    col: 12,
    specialFields: [
      new Filter({
        type: "select",
        selectName: "PAYMENT_CONTRACTS",
        text: "Select payment contract",
        key: "paymentContract",
        col: 6,
      }),
      new Filter({
        key: "tgeTime",
        type: "date-time",
        text: "TGE Time",
        col: 6,
        require: true,
      }),
      new Filter({
        key: "tgeUnlockPercent",
        type: "input",
        text: "TGE Unlock Percent",
        col: 4,
        require: true,
        isEnum: true,
      }),
      new Filter({
        key: "cliffMonths",
        type: "input",
        text: "Cliff Months",
        col: 4,
        require: true,
        isEnum: true,
      }),
      new Filter({
        key: "number0fLinearMonth",
        type: "input",
        text: "Number 0f Linear Month",
        col: 4,
        require: true,
        isEnum: true,
      }),
    ],
  }),
];

export const INOProducts = SearchHigherComponent({
  title: "INO Products",
  endpoint: EndpointConstant.INO_LIST,
  createEndpoint: EndpointConstant.INO_ADD,
  updateEndpoint: EndpointConstant.INO_UPDATE,
  updateMethod: "POST",
  columns: INOProductColumns,
  createFields: INOProductCreateFields,
  updateFields: INOProductCreateFields,
});
export const INORound = SearchHigherComponent({
  title: "INO Rounds",
  endpoint: EndpointConstant.INO_ROUND_LIST,
  createEndpoint: EndpointConstant.INO_ROUND_ADD,
  updateEndpoint: EndpointConstant.INO_ROUND_UPDATE,
  syncEndpoint: EndpointConstant.INO_ROUND_SYNC,
  syncMethod: "PUT",
  updateMethod: "POST",
  columns: INORoundColumns,
  createFields: INORoundCreatedFields,
  updateFields: INORoundUpdateFields,
});

export default function INOList() {
  return (
    <div>
      <INORound />
      <INOProducts />
    </div>
  );
}
