import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";

const columns = [
  {
    key: "id",
    label: "ID",
    isId: true,
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "creationDate",
    label: "Created time",
    isTime: true,
  },
];
export default SearchHigherComponent({
  endpoint: EndpointConstant.ADMIN_LIST,
  title: "Administrator List",
  columns,
});
