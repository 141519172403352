import { FETCH_CONTRACT } from '../constants/reducerConstant';

const initialState = {
  contract: null,
};

// eslint-disable-next-line default-param-last
function AppReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case FETCH_CONTRACT:
      return { ...state, contract: payload };
    default:
      return { ...state };
  }
}

export default AppReducer;
