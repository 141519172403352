import { AdminStoreConstant } from "./adminConstants";

const initialState = {
  information: null,
  currentUser: null,
  currentTokenId: null,
  informationLoading: true,
  selectedPost: null,
};

function AdminReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case AdminStoreConstant.SET_SELECT_POST:
      return { ...state, selectedPost: payload };
    case AdminStoreConstant.SET_CURRENT_TOKEN_ID:
      return { ...state, currentTokenId: payload };
    case AdminStoreConstant.SET_INFORMATION_LOADING:
      return { ...state, informationLoading: payload };
    case AdminStoreConstant.FETCH_USER:
      return { ...state, currentUser: payload };
    case AdminStoreConstant.GET_PROFILE:
      return { ...state, information: payload };
    default:
      return { ...state };
  }
}

export default AdminReducer;
