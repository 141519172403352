import {
  Collapse,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TableContainer,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { _switchPopup } from "../store/setting/settingActions";
import { get, post, put } from "../utils/api";
import CustomPagination from "./CustomPagination";
import CustomTable from "./CustomTable";
import ItemCreate from "./ItemCreate";
import ItemDetail from "./ItemDetail";
import Meta from "./Meta";
import SFPostPreview from "./SFPostPreview";
import Toolbar from "./Toolbar";

function SearchHigherComponent({
  columns,
  exportColumns,
  SpecialComponent,
  title,
  exportLink,
  createFields,
  updateFields,
  requireFilter,
  deleteEndpoint,
  defaultFilter = {},
  endpoint,
  component,
  updateEndpoint,
  createEndpoint,
  filterBy,
  showCheckbox,
  getRoles,
  isUserAPI,
  reMintEndpoint,
  isNFTTemplate,
  NoticeComponent,
  isResend,
  method,
  updateMethod,
  hasUploadFile,
  updateGA,
  ...props
}) {
  function WrappedComponent() {
    let { userId } = useParams();
    const [data, setData] = useState(null);
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState(null);
    const [isReload, setIsReload] = useState(false);
    const [checked, setChecked] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedEdit, setSelectedEdit] = useState(null);
    const [showCreate, setShowCreate] = useState(false);
    const dispatch = useDispatch();
    const [exportData, setExportData] = useState(null);
    const [baseFilter, setBasicFilter] = useState(defaultFilter);
    const random = Math.floor(100000 + Math.random() * 900000);

    useEffect(() => {
      let tempFilters = {};
      if (props.ownerAddress) {
        tempFilters = { ownerAddress: props.ownerAddress };
      }
      if (props.address) {
        tempFilters = { address: props.address };
      }
      if (props.tokenId) {
        tempFilters = { tokenId: props.tokenId };
      }
      if (props.userId) {
        tempFilters = { userId: props.userId.toString() };
      }
      setBasicFilter(tempFilters);
      setFilters(tempFilters);
    }, [userId]);

    useEffect(() => {
      setData(null);
      if (method === "GET") {
        get(endpoint, (data) => {
          if (props.localFilterKey) {
            data = data.filter((d) => d.nftTier === props.localFilterValue);
          }
          setData({ items: data });
        });
      } else {
        if (filters) {
          if (!requireFilter || (requireFilter && filters[requireFilter])) {
            const body = {
              page,
              pageSize,
              responseMeta: true,
              filters,
            };
            post(endpoint, body, (data) => {
              setData(data);
            });
          } else {
            setData({
              items: [],
            });
            toast(`Please enter ${requireFilter} for filter data`);
          }
        }
      }
    }, [filters, page, pageSize, isReload]);

    const _handleChangePageSize = (pageSize) => {
      setPageSize(pageSize);
      setPage(1);
    };

    const _handleChangePage = (page) => {
      setPage(page);
    };

    const _handleSearch = (newFilters) => {
      setPage(1);
      setFilters({ ...baseFilter, ...newFilters });
      setIsReload(!isReload);
    };

    const _handleReset = () => {
      setFilters(baseFilter);
      setPage(1);
      setPageSize(10);
    };

    const _onReload = () => setIsReload(!isReload);

    const _handleDelete = (data) => {
      const id = component === "nfts" ? data.tokenId : data.id;
      let body = { id: data.id };
      if (props.deleteBy === "postId") body = { postId: data.id };
      dispatch(
        _switchPopup({
          title: "Delete item # " + id,
          content: "Are you for this action",
          _handleSubmit: () => {
            post(deleteEndpoint, body, () => {
              toast.success("deleted");
              _onReload();
            });
          },
        })
      );
    };

    const _handleUpdateGA = (data) => {
      let body = { userId: data.id };
      dispatch(
        _switchPopup({
          title: "Turn Off Google Authentication User #" + body.userId,
          content: "Are you for this action",
          _handleSubmit: () => {
            put(updateEndpoint, body, () => {
              toast.success("Successful turn off GA");
              _onReload();
            });
          },
        })
      );
    };

    const _handleExport = () => {
      dispatch(
        _switchPopup({
          title: "Export just only all of items",
          content: "Are you for this action",
          _handleSubmit: async () => {
            let data = [];
            const pageCount = await new Promise((resolve) => {
              post(
                `${endpoint}`,
                {
                  page: 1,
                  pageSize: 1000,
                  filters,
                },
                (data) => {
                  resolve(data.pageCount);
                },
                (error) => resolve(error)
              );
            });
            for (let index = 1; index <= pageCount; index++) {
              const items = await new Promise((resolve) => {
                post(
                  `${endpoint}`,
                  {
                    page: index,
                    pageSize: 1000,
                    filters,
                  },
                  (data) => {
                    resolve(data.items);
                  },
                  (error) => resolve(error)
                );
              });
              data = [...data, ...items];
            }
            setExportData(data);
          },
        })
      );
    };

    const _handleReMint = (data) => {
      dispatch(
        _switchPopup({
          title: "Remint item # " + data.tokenId,
          content: "Are you for this action",
          _handleSubmit: () => {
            post(
              `${reMintEndpoint}?tokenId=${data.tokenId}`,
              {},
              () => {
                toast.success("Reminted");
                setIsReload(!isReload);
              },
              (error) => toast.error(error.msg)
            );
          },
        })
      );
    };

    useEffect(() => {
      if (exportData) {
        // console.log({ exportData, columns });
        document.getElementById(`export-btn-${random}`).click();
        setExportData(null);
      }
    }, [exportData, random]);

    return (
      <Container>
        <Grid container spacing={1}>
          <Grid item xs={12} mt={3}>
            {title && !userId && !props.isProfile && !props.tokenId && (
              <Typography variant="h4">{title}</Typography>
            )}
          </Grid>
          <Grid item xs={12}>
            <Toolbar
              filters={filters}
              filterBy={filterBy}
              updateFields={updateFields}
              createFields={createFields}
              createEndpoint={createEndpoint}
              defaultFilter={defaultFilter}
              hasUploadFile={hasUploadFile}
              _onReload={() => setIsReload(!isReload)}
              _onReset={_handleReset}
              _onSearch={_handleSearch}
              _handleShowCreate={() => setShowCreate(true)}
              _handleExport={_handleExport}
              {...props}
            />
          </Grid>
          {data && data.meta && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    color="primary"
                  />
                }
                label="Show statistic"
              />
              <Collapse in={checked}>
                <Meta meta={data.meta} />
              </Collapse>
            </Grid>
          )}
          <Grid item xs={12}>
            {NoticeComponent && <NoticeComponent />}
            <Divider sx={{ borderStyle: "dashed", marginBottom: "2rem" }} />
            <Paper variant="outlined">
              <TableContainer
                style={{
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                <CustomTable
                  columns={columns}
                  data={data}
                  pageSize={pageSize}
                  updateFields={updateFields}
                  updateGA={updateGA}
                  createFields={createFields}
                  showCheckbox={showCheckbox}
                  _handleSelectItem={(e) => setSelectedItem(e)}
                  _handleEditItem={(e) => setSelectedEdit(e)}
                  _handleUpdateGA={_handleUpdateGA}
                  _handleDelete={_handleDelete}
                  deleteEndpoint={deleteEndpoint}
                  //remint
                  reMintEndpoint={reMintEndpoint}
                  _handleReMint={_handleReMint}
                  isProfile={props.isProfile}
                  isResend={isResend}
                  _onReload={() => setIsReload(!isReload)}
                  {...props}
                />
              </TableContainer>
              <CustomPagination
                data={data}
                changePageSize={_handleChangePageSize}
                changePage={_handleChangePage}
              />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <ItemDetail
              data={selectedItem}
              _onClose={() => setSelectedItem(null)}
            />
            {updateFields && (
              <ItemCreate
                open={selectedEdit !== null}
                type="update"
                defaultData={selectedEdit ? selectedEdit : {}}
                updateFields={updateFields}
                endpoint={updateEndpoint}
                _onClose={() => setSelectedEdit(null)}
                _onReload={_onReload}
                updateMethod={updateMethod}
                {...props}
              />
            )}
            {createFields && (
              <ItemCreate
                open={showCreate}
                type="create"
                defaultData={{}}
                createFields={createFields}
                endpoint={createEndpoint}
                _onClose={() => setShowCreate(false)}
                _onReload={_onReload}
                {...props}
              />
            )}
          </Grid>
          <CSVLink
            asyncOnClick={true}
            data={exportData ? exportData : []}
            headers={exportColumns ? exportColumns : columns}
            hidden
            id={`export-btn-${random}`}
            filename={`export-${title ? title.toLowerCase().replace(" ", "-") : ""
              }-${moment().format("YYYY-MM-DD_hh-mm-ss")}.csv`}
          />
        </Grid>
        <SFPostPreview />
      </Container>
    );
  }
  return WrappedComponent;
}

export default SearchHigherComponent;
