import { Box, IconButton, Typography } from "@mui/material";
import { IconCopy, IconLineDashed } from "@tabler/icons";
import React from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatAddress_1 } from "../settings/format";
import { AdminStoreConstant } from "../store/admin/adminConstants";

export default function LinkToOwnerAddress({ address, email }) {
  const dispatch = useDispatch();

  return address ? (
    <Box>
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            color: "#3f51b5",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch({
              type: AdminStoreConstant.FETCH_USER,
              payload: address,
            });
          }}
        >
          {formatAddress_1(address)}
        </Box>
        <IconButton
          color="primary"
          sx={{ p: 0.5, marginLeft: "2px" }}
          onClick={() => {
            toast.success("Copied");
            navigator.clipboard.writeText(address);
          }}
        >
          <IconCopy stroke={2} style={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>
      {email && <Typography variant="caption">{email}</Typography>}
    </Box>
  ) : (
    <IconLineDashed stroke={2} style={{ width: 16, height: 16 }} />
  );
}
