import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";
import { fAmount } from "../../utils/format";

const columns = [
  {
    key: "id",
    label: "ID",
  },
  {
    key: "tokenId",
    label: "Token ID",
    isNFTTokenId: true,
  },
  {
    key: "nft.name",
    label: "NFT",
    render: (data) => <>{data.nft ? data.nft.name : '--'}</>
  },
  {
    key: "email",
    label: "Email",
  },
  {
    key: "mallPoint",
    label: "Mall Point",
    render: (data) => <>{fAmount(data.mallPoint)}</>
  },
  {
    key: "creationDate",
    label: "Created Time",
    isTime: true,
  },
  {
    key: "modificationDate",
    label: "Modification Time",
    isTime: true,
  },
];

const filterBy = [
  new Filter({
    key: "tokenId",
    type: "input",
    text: "Token ID",
  }),

];

const MallPointList = (props) =>
  SearchHigherComponent({
    endpoint: EndpointConstant.MALL_POINT_GET_LIST,
    title: "Mall Point List",
    columns,
    filterBy,
    ...props,
  })();

export default MallPointList;
