import { Container, Grid, styled, Typography } from "@mui/material";
import React from "react";

//-----------------------------------------------------

const CusGrid = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'center',
}));
//-----------------------------------------------------

export default function ComingSoon() {

    return (
        <React.Fragment>
            <Container maxWidth="md">
                <CusGrid container>
                    <Grid item>
                        <img src='/images/login/plane.png' alt="comingsoon" width='100%' />
                    </Grid>
                    <Grid item xs={12} m={5} >
                        <Typography variant="h1" textAlign="center">
                            Welcome to SPLabs x Raon
                        </Typography>
                    </Grid>
                    <Grid item>
                        <img src='/images/login/maining.png' alt="comingsoon" width='100%' />
                    </Grid>
                </CusGrid>
            </Container>
        </React.Fragment >
    );
}
