import PasswordIcon from "@mui/icons-material/Password";
import {
  IconAccessible, IconBuildingStore, IconFileCertificate, IconHistory, IconLayoutDashboard, IconLayoutList, IconRefresh, IconReplace, IconSend,
  IconShieldLock, IconUserCircle, IconUsers
} from "@tabler/icons";
import { USER_SCOPE } from "../../settings/constants";
const navConfig = [

  //KT RAON ADMIN

  {
    title: "overviews",
    path: "/dashboard/overview",
    icon: <IconLayoutDashboard />,
  },
  {
    title: "users",
    path: "/dashboard/users",
    icon: <IconUsers />,
    scopes: USER_SCOPE,
  },
  {
    title: "NFT",
    icon: <IconShieldLock />,
    scopes: USER_SCOPE,
    children: [
      {
        title: "List",
        path: "/dashboard/nft/list",
        icon: <IconLayoutList />,
      },
      {
        title: "Swap List",
        path: "/dashboard/nft/swap",
        icon: <IconReplace />,
      },

      {
        title: "Mall Point List",
        path: "/dashboard/nft/mall-point",
        icon: <IconBuildingStore />,
      },
      {
        title: "transaction history",
        path: "/dashboard/nft/transactions",
        icon: <IconHistory />,
      },
      {
        title: "Check Swap",
        path: "/dashboard/nft/sync-swap-job",
        icon: <IconRefresh />,
      },
    ],
  },
  {
    title: "Airdrop NFT",
    path: "/dashboard/airdrop/airdrop-nft",
    icon: <IconSend />,
    scopes: USER_SCOPE,
  },
  // {
  //   title: "contracts",
  //   path: "/dashboard/system/contracts",
  //   icon: <IconFileCertificate />,
  //   scopes: USER_SCOPE,
  // },
  {
    title: "Admin",
    icon: <IconAccessible />,
    scopes: USER_SCOPE,
    children: [
      {
        title: "administrations",
        path: "/dashboard/admin/administrations",
        icon: <IconUserCircle />,
      },
      {
        title: "change password",
        path: "/dashboard/admin/change-password",
        icon: <PasswordIcon />,
      },
    ],
  },

];

export default navConfig;
