import { Chip, styled, Box, IconButton } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet } from "react-router-dom";

import AccountPopover from "./AccountPopover";
import DashboardSidebar from "./DashboardSidebar";
import CurrentToken from "../../components/CurrentToken";
import CurrentUserProfile from "../../components/CurrentUserProfile";
import { isProduction } from "../../settings/constants";
import {
  _getBCConfiguration,
  _getConfig,
  _getContracts,
} from "../../store/setting/settingActions";
import { IconMenu2 } from "@tabler/icons";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
  background: 'url(/images/login/bg2.jpg)',
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_getContracts());
    dispatch(_getConfig());
    dispatch(_getBCConfiguration());
  }, [dispatch]);

  return (
    <RootStyle>
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <Box
        sx={{
          position: "absolute",
          right: 24,
          top: 24,
          zIndex: 1,
        }}
      >
        <Box display="flex" alignItems={"center"}>
          <Timer />
          <AccountPopover />
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          left: 24,
          top: 24,
          zIndex: 1,
          height: 68,
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton onClick={() => setOpen(!open)}>
          <IconMenu2 />
        </IconButton>
      </Box>
      <MainStyle>
        <Outlet />
      </MainStyle>
      <CurrentUserProfile />
      <CurrentToken />
    </RootStyle>
  );
}

const Timer = () => {
  const [now, setNow] = useState(moment.now());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(moment.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [setNow]);

  return (
    <>
      <Chip
        sx={{
          width: 200,
          fontWeight: 900,
          mr: 1,
        }}
        label={`${isProduction ? "PRODUCTION" : "DEVELOP"}`}
      ></Chip>
      <Chip
        sx={{
          width: 200,
          fontWeight: 900,
          mr: 1,
        }}
        label={`UTC: ${moment(now).utcOffset(0).format("yyyy-MM-DD HH:mm:ss")}`}
      ></Chip>
      <Chip
        sx={{
          width: 200,
          fontWeight: 900,
          mr: 1,
        }}
        label={`Local: ${moment(now).format("yyyy-MM-DD HH:mm:ss")}`}
      ></Chip>
    </>
  );
};
