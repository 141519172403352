import { Box, Typography } from "@mui/material";
import axios from "axios";
import { toast } from "react-toastify";
import { API } from "../settings/constants";
import { logout } from "../utils/auth";
import { getAccessToken } from "./auth";
export const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export function get(endpoint, successCallback, errorCallback) {
  myFetch("GET", endpoint, undefined, successCallback, errorCallback);
}

export function post(endpoint, body, successCallback, errorCallback) {
  myFetch("POST", endpoint, body, successCallback, errorCallback);
}

export function put(endpoint, body, successCallback, errorCallback) {
  myFetch("PUT", endpoint, body, successCallback, errorCallback);
}

export function _delete(endpoint, body, successCallback, errorCallback) {
  myFetch("DELETE", endpoint, body, successCallback, errorCallback);
}

export const alertError = (error) => {
  alert(error.code + (error.msg ? ": " + error.msg : ""));
};

async function myFetch(method, endpoint, body, successCallback, errorCallback) {
  let url = API + endpoint;

  body = JSON.stringify(body);

  let headers = defaultHeaders;
  headers["Authorization"] = "bearer " + getAccessToken();
  headers["Accept-Language"] = "en-US,en;q=0.5";

  const config = { headers };

  let response = null;

  try {
    switch (method) {
      case "POST":
        response = await axios.post(url, body, config);
        break;
      case "PUT":
        response = await axios.put(url, body, config);
        break;
      case "DELETE":
        response = await axios.delete(url, body, config);
        break;
      default:
        response = await axios.get(url, config);
        break;
    }
    _handleSuccess(response, successCallback);
  } catch (error) {
    _handleError(error, errorCallback);
  }
}

const _handleSuccess = (
  response,
  successCallback = () => {
    return true;
  }
) => {
  const { data } = response;
  successCallback(data);
};

const _handleError = (
  error,
  errorCallback = () => {
    return false;
  }
) => {
  const { code, message, response } = error;
  if (code === "ERR_BAD_REQUEST" || code === "ERR_BAD_RESPONSE") {
    errorCallback(response.data);
  } else if (code === "ERR_NETWORK") {
    logout();
  } else {
    console.log(error);
    toast.error(() => (
      <Box>
        <Typography variant="body2" fontWeight={500}>
          {message}
        </Typography>
        <Typography variant="caption">{code}</Typography>
      </Box>
    ));
  }
};

export async function postWithFormData(
  endpoint,
  body,
  successCallback,
  errorCallback
) {
  let url = API + endpoint;

  let headers = {
    Authorization: "bearer " + getAccessToken(),
    "Content-Type": "multipart/form-data",
    accept: "application/json",
  };

  const config = { headers };

  let response = null;

  try {
    response = await axios.post(url, body, config);
    _handleSuccess(response, successCallback);
  } catch (error) {
    _handleError(error, errorCallback);
  }
}

// const handleResponse = (
//   response,
//   successCallback,
//   errorCallback = () => {
//     return false;
//   }
// ) => {
//   console.log(response);
//   const { status, data } = response;
//   if (status === 200) {
//     successCallback(data);
//   } else if (status === 400) {
//     errorCallback(data);
//   } else if (status === 401) {
//     // logout();
//   } else {
//     errorCallback();
//     toast.error(`Status: ${status}`);
//     toast.error(
//       `${response.message}. ${response.code}. ${status ? status : ""}`
//     );
//   }
// };

// const handleResponse = (
//   response,
//   successCallback,
//   errorCallback = () => {
//     return false;
//   }
// ) => {
//   const { status, data } = response;
//   if (status === 200) {
//     const { errors } = data;
//     if (errors) {
//       errorCallback(data);
//     } else {
//       successCallback(data);
//     }
//   } else {
//     // logout();
//     successCallback();
//     toast.error(
//       `${response.message}. ${response.code}. ${status ? status : ""}`
//     );
//   }
// };
