import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Drawer, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FETCH_USER } from "../constants";
import CustomLoading from "../HOC/CustomLoading";
import { AdminEndpoint } from "../store/admin/adminConstants";
import { post } from "../utils/api";
import Actions from "./user-detail/Actions";
import Info from "./user-detail/Info";
import UserBalances from "./user-detail/UserBalances";
import UserTransaction from "./user-detail/UserTransactions";

export default function CurrentUserProfile() {
  const { admin } = useSelector((state) => state);
  const { currentUser } = admin;
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [isReload, setIsReload] = useState(false);
  const [mounted, setMounted] = useState(true);

  useEffect(() => {
    if (currentUser) {
      post(
        AdminEndpoint.ADMIN_USERS,
        {
          page: 1,
          pageSize: 1,
          responseMeta: false,
          filters: {
            address: currentUser,
          },
        },
        (data) => {
          const { itemCount, items } = data;
          if (itemCount > 0) {
            setData(items[0]);
          } else {
            setData("UNKNOWN");
          }
        }
      );
    } else {
      setData(null);
    }
  }, [currentUser]);

  useEffect(() => {
    return () => {
      setMounted(false);
    };
  }, []);

  const _onClose = () => {
    dispatch({
      type: FETCH_USER,
      payload: null,
    });
  };

  return (
    <Drawer anchor="right" open={currentUser !== null} onClose={_onClose}>
      <Box width="70vw" p={3}>
        {data ? (
          data === "UNKNOWN" ? (
            <Typography>Unknown User</Typography>
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Button variant="contained" onClick={_onClose}>
                  <ArrowBackIos fontSize="small" />
                  Back
                </Button>
              </Grid>
              <Info data={data} />
              <UserBalances data={data} />
              <Actions data={data} _success={() => setIsReload(!isReload)} />
              <UserTransaction data={data} />
            </Grid>
          )
        ) : (
          <CustomLoading />
        )}
      </Box>
    </Drawer>
  );
}
