import {
  Box,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import GoogleAuthent from "../components/auth/GoogleAuthent";
import { EndpointConstant } from "../constants/endpoint";
import CustomTable from "../HOC/CustomTable";
import { formatAmount, formatShortAmount } from "../settings/format";
import { AdminEndpoint } from "../store/admin/adminConstants";
import { post } from "../utils/api";

function Dashboard() {
  const { admin } = useSelector((state) => state);
  const { information } = admin;
  const turnOnGa = information?.gaEnable;

  const [userCount, setUserCount] = useState(0);
  const [nftCount, setNFTCount] = useState(0);
  const [burnedNFTCount, setBurnedNFTCount] = useState(0);
  const [exchangeRates, setExchangeRates] = useState(null);
  const [recentUsers, setRecentUsers] = useState(null);
  const [recentWithdrawals, setRecentWithdrawals] = useState(null);

  useEffect(() => {
    post(
      AdminEndpoint.ADMIN_USERS,
      {
        page: 1,
        pageSize: 5,
      },
      (data) => {
        setUserCount(data.itemCount);
        setRecentUsers(data);
      }
    );
    post(
      EndpointConstant.NFT_GET_LIST,
      {
        page: 1,
        pageSize: 1,
      },
      (data) => setNFTCount(data.itemCount)
    );
    post(
      EndpointConstant.NFT_GET_LIST,
      {
        page: 1,
        pageSize: 1,
        filters: {
          status: "3",
        },
      },
      (data) => setBurnedNFTCount(data.itemCount)
    );
    post(
      EndpointConstant.EXCHANGE_RATE_GET_LIST,
      {
        page: 1,
        pageSize: 1000,
      },
      (data) => setExchangeRates(data.items)
    );
    post(
      EndpointConstant.FUND_LOGS,
      {
        page: 1,
        pageSize: 5,
        filters: {
          type: "0",
        },
      },
      (data) => setRecentWithdrawals(data)
    );
  }, []);

  return (<>
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <Link to="/dashboard/users" style={{ textDecoration: "none" }}>
          <Paper variant="outlined" sx={{ height: "100%" }}>
            <Box p={2}>
              <Typography>User</Typography>
              <Typography>Total: {formatAmount(userCount)}</Typography>
              {/* <Typography>Baned: {0}</Typography> */}
            </Box>
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={3}>
        <Link to="/dashboard/nft/list" style={{ textDecoration: "none" }}>
          <Paper variant="outlined" sx={{ height: "100%" }}>
            <Box p={2}>
              <Typography>NFT</Typography>
              <Typography>Total: {formatShortAmount(nftCount)}</Typography>
              <Typography>Burned: {formatAmount(burnedNFTCount)}</Typography>
            </Box>
          </Paper>
        </Link>
      </Grid>
      {exchangeRates && (
        <Grid item xs={6}>
          <Link
            to="/dashboard/system/exchange-rate"
            style={{ textDecoration: "none" }}
          >
            <Paper variant="outlined" sx={{ height: "100%" }}>
              <Box p={2}>
                {exchangeRates?.map((item, index) => (
                  <Typography key={index}>
                    1 {item.asset} = {item.rate} {item.toAsset} in{" "}
                    {item.provider}
                  </Typography>
                ))}
              </Box>
            </Paper>
          </Link>
        </Grid>
      )}
      <Grid item xs={6}>
        <Link to="/dashboard/users" style={{ textDecoration: "none" }}>
          <Paper variant="outlined">
            <Typography p={1}>Recent users</Typography>
            {recentUsers && (
              <CustomTable
                data={recentUsers}
                columns={[
                  {
                    key: "email",
                    label: "Email",
                  },

                  {
                    key: "address",
                    label: "Address",
                    isAddress: true,
                  },
                ]}
              />
            )}
          </Paper>
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Link to="/dashboard/fund/logs" style={{ textDecoration: "none" }}>
          <Paper variant="outlined">
            <Typography p={1}>Recent withdrawals</Typography>
            {recentWithdrawals && (
              <CustomTable
                data={recentWithdrawals}
                columns={[
                  {
                    key: "userId",
                    label: "User ID",
                  },

                  {
                    key: "amount",
                    label: "Amount",
                    isAmount: true,
                  },
                  {
                    key: "asset",
                    label: "Asset",
                  },
                  {
                    key: "status",
                    label: "Status",
                    isEnum: true,
                    enumKey: "BalanceTransactionStatus",
                    isStatus: true,
                  },
                ]}
              />
            )}
          </Paper>
        </Link>
      </Grid>
    </Grid>
    {/* <GoogleAuthent
      showPopup={!turnOnGa}
      onClose={turnOnGa}
    /> */}
  </>
  );
}
export default Dashboard;
