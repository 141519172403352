import { UploadFile } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
import {
  AppBar,
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IconCheckbox } from "@tabler/icons";
import React, { useState } from "react";
import { _convertCsvToArray } from "../settings/format";
import { post } from "../utils/api";

export default function UploadForCreate({
  createEndpoint,
  createFields,
  disabled,
}) {
  const [open, setOpen] = useState(false);
  const [uploadList, setUploadList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [creating, setCreating] = useState(false);
  const [uploading, setUploading] = useState(false);

  const _handleCreate = async () => {
    setCreating(true);
    const oldTemp = [...statusList];
    for (let index = 0; index < uploadList.length; index++) {
      const element = uploadList[index];
      const response = await new Promise((resolve) => {
        post(
          createEndpoint,
          element,
          () => {
            resolve(1);
          },
          () => {
            resolve(2);
          }
        );
      });
      oldTemp[index] = response;
      const temp = [...oldTemp];
      setStatusList(temp);
      await new Promise((r) => setTimeout(r, 2000));
    }
    setCreating(false);
  };

  const _handleUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    const statusList = [];
    reader.onload = (e) => {
      setUploading(true);
      let array = _convertCsvToArray(e.target.result);
      const temp = [...array];
      const list = [];
      temp.forEach((i) => {
        statusList.push(0);
        const check = {};
        createFields.forEach((f) => {
          check[f.key] = f.isEnum ? parseInt(i[f.key]) : i[f.key];
          if (f.type === "special") {
            const special = {};
            f.specialFields.forEach((s) => {
              special[s.key] = s.isEnum ? parseInt(i[s.key]) : i[s.key];
            });
            check[f.key] = [special];
          }
        });
        list.push(check);
      });
      setStatusList(statusList);
      setUploadList(list);
      setUploading(false);
    };
    reader.readAsText(file);
  };

  return (
    <>
      <Button
        color="secondary"
        onClick={() => setOpen(true)}
        variant="outlined"
        disabled={disabled}
      >
        <UploadFile fontSize="small" />
        Upload
      </Button>
      {!disabled && (
        <Drawer
          anchor="right"
          open={open}
          onClose={uploading ? null : () => setOpen(false)}
        >
          <Box width={1000} className="item-detail">
            <div>
              <AppBar position="sticky" sx={{ py: 1 }}>
                <Grid container alignItems="center">
                  <Grid item>
                    <IconButton
                      onClick={() => setOpen(false)}
                      disabled={creating}
                    >
                      <ArrowBackIcon fontSize="small" sx={{ fill: "#fff" }} />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">Back</Typography>
                  </Grid>
                </Grid>
              </AppBar>
              <Grid container>
                <Grid item xs={12}>
                  <form
                    style={{ padding: 16 }}
                    onSubmit={_handleCreate}
                    noValidate
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      sx={{ mr: 1 }}
                      onChange={_handleUpload}
                      component="label"
                      loading={uploading}
                    >
                      Upload
                      <input type="file" hidden />
                    </LoadingButton>
                    <LoadingButton
                      loading={creating}
                      type="submit"
                      variant="contained"
                    >
                      Submit
                    </LoadingButton>
                    <TableContainer sx={{ maxHeight: 440 }}>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {createFields.map((field, index) => {
                              if (field.type === "special") {
                                return field.specialFields.map((s, index) => (
                                  <TableCell key={index}>
                                    {s.text} <br /> ({s.key})
                                  </TableCell>
                                ));
                              }
                              return (
                                <TableCell key={index}>
                                  {field.text} <br /> ({field.key})
                                </TableCell>
                              );
                            })}
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {uploadList.map((item, index) => (
                            <TableRow item xs={12} key={index}>
                              {createFields.map((field, index) => {
                                if (field.type === "special") {
                                  return field.specialFields.map((s, index) => (
                                    <TableCell key={index}>
                                      {item.items[0][s.key]}
                                    </TableCell>
                                  ));
                                } else {
                                  return (
                                    <TableCell key={index}>
                                      {item[field.key]}
                                    </TableCell>
                                  );
                                }
                              })}
                              <TableCell>
                                <IconButton
                                  color={
                                    statusList[index]
                                      ? statusList[index] === 1
                                        ? "success"
                                        : "error"
                                      : "default"
                                  }
                                >
                                  <IconCheckbox />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </form>
                </Grid>
              </Grid>
            </div>
          </Box>
        </Drawer>
      )}
    </>
  );
}
