import { SettingStoreConstant } from "./settingConstants";

const initialState = {
  popupData: null,
  enums: null,
  scopes: {},
  contracts: null,
  bcConfiguration: null,
};

export const SettingReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SettingStoreConstant.SET_BC_CONFIGURATION:
      return { ...state, bcConfiguration: payload };
    case SettingStoreConstant.FETCH_CONFIG:
      return { ...state, enums: payload };
    case SettingStoreConstant.FETCH_CONTRACTS:
      return { ...state, contracts: payload };
    case SettingStoreConstant.SWITCH_POPUP:
      return { ...state, popupData: payload };
    default:
      return { ...state };
  }
};
