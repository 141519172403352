import { EndpointConstant } from "../../constants/endpoint";
import SearchHigherComponent from "../../HOC/SearchHigherComponent";
import { Filter } from "../../settings";

const columns = [
  {
    key: "userId",
    label: "User ID",
  },
  {
    key: "amount",
    label: "Amount",
    isAmount: true,
  },
  {
    key: "asset",
    label: "Asset",
  },
  {
    key: "creationDate",
    label: "Created Time",
    isTime: true,
  },
  {
    key: "modificationDate",
    label: "Modification Date",
    isTime: true,
  },
];

const filterBy = [
  new Filter({
    key: "userId",
    type: "input",
    text: "User ID",
  }),
];

const FundList = (props) =>
  SearchHigherComponent({
    endpoint: EndpointConstant.FUND_LIST,
    title: "Fund list",
    columns,
    filterBy,
    ...props,
  })();

export default FundList;
