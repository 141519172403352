import { LoadingButton } from "@mui/lab";
import {
  Box,
  Card,
  Container,
  Stack,
  Typography,
  styled
} from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { EndpointConstant } from "../../constants/endpoint";
import { post } from "../../utils/api";

export const LightGreenButton = styled(LoadingButton)(({ theme }) => ({
  color: 'white',
  background: '#75CDC9',
  border: '1px solid #fff',
  borderRadius: 8,
  fontWeight: 'bold',
  fontSize: 14,
  textAlign: 'center',
  textTransform: 'none',
  padding: '0.25rem 1rem',
  cursor: 'pointer',
  margin: '10px',
  minWidth: 100,
  boxShadow: '1px 1px 10px 0 rgba(0,0,0,0.2)',
  '& a': {
    color: 'white',
    textDecoration: 'none',
  },
  '&:hover': {
    boxShadow:
      '1px 1px 10px 0 rgba(0,0,0,0.2),inset 0 0 10px 5px rgba(255,255,255,0.3)',
    border: '1px solid #75CDC9',
    background: '#75CDC9',
  },
  '&.Mui-disabled': {
    boxShadow: 'unset',
    background: 'transparent',
    border: '1px solid rgba(145, 158, 171, 0.5)',
  },
}));

export default function SyncSwapJob() {
  const [loading, setLoading] = useState(false)
  const handleSync = () => {
    setLoading(true)
    post(
      EndpointConstant.SYNC_JOB_CHECK_SWAP, {},
      () => {
        toast.success('Sync Job Check Swap success')
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    );
  };

  return (
    <Container>
      <Stack mb={2} sx={{ alignItems: "start" }}>
        <Typography variant="h4" mr={2}>
          Sync Job Check Swap
        </Typography>
        <Typography variant="body1" mt={1}>
          Job Check Swap is always run at 00:00 UTC
        </Typography>
        <Typography variant="body1" mt={1}>
          If you want manual run, you should click below button
        </Typography>
      </Stack>
      <Stack alignItems={'flex-start'}>
        <LightGreenButton
          loading={loading}
          size="large"
          onClick={handleSync}>
          Sync
        </LightGreenButton>
      </Stack>
    </Container>
  );
}

