export default function Table(theme) {
  return {
    MuiTableContainer: {
      styleOverrides: {
        root: { paddingTop: 10 },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '4px 10px',
          fontSize: 12,
          background: '#fff'
        },
      },
    },
  };
}
