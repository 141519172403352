export const AdminStoreConstant = {
  GET_PROFILE: "GET_PROFILE",
  FETCH_USER: "FETCH_USER",
  SET_INFORMATION_LOADING: "SET_INFORMATION_LOADING",
  SET_CURRENT_TOKEN_ID: "SET_CURRENT_TOKEN_ID",
  SET_SELECT_POST: "SET_SELECT_POST",
};

export const AdminEndpoint = {
  ADMIN_LOGIN: `/identity/admin/login`,
  ADMIN_PROFILE: `/identity/admin/profile`,
  ADMIN_USERS: `/identity/admin/users`,
  ADMIN_USERS_INACTIVE_GA: `/identity/admin/inactive-2fa`,
};
