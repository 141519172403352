export const library = {
  BA_REWARD: "Pool promotion",
  HOLDER: "Pool holder",
  REWARD: "Pool reward",
  totalStaking: "Total staking",
  totalInitAmount: "Total init amount",
  totalReleased: "Total released",
  totalCurrentAmount: "Total current amount",
  totalInitUsdAmount: "Total USD init amount",
  HOLDER_COMMISSION: "Holder commission",
  AFFILIATE_COMMISSION: "Affiliate commission",
  RELEASE: "Release",
  DAILY_RELEASE: "Daily release",
  RANKING_BONUS: "Ranking bonus",
  ADD: "Add",
  NO_RANK: "Membership",
  DIAMOND: "Diamond",
  GOLD: "Gold",
  SILVER: "Silver",
  //rank
  PRO: "Pro",
  MASTER: "Master",
  QUARTER: "Quarter",
  SUPERVISOR: "Supervisor",
  COACH: "Coach",
  FOUNDER: "Founder",
  MEMBER: "Member",
  STARTER: "Starter",
  HALF: "Half",
  MAIN: "Main",
  NO_PACKAGE: "No package",
  ACTIVE: "Active",
  BLOCK: "Block",
  TRANSFER: "Transfer",
  WITHDRAW: "Withdraw"
};
