import { LoadingButton } from "@mui/lab";
import {
  Button,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import { IconEye, IconEyeOff } from "@tabler/icons";
import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { IdentityEndpoint } from "../../../constants/endpoint";
import { _getAdminProfile } from "../../../store/admin/adminActions";
import { post } from "../../../utils/api";
import { setAccessToken } from "../../../utils/auth";

// ----------------------------------------------------------------------

const LoginBox = styled(Stack)(({ theme }) => ({
  "& svg": {
    stroke: "#28A3AB",
  },
}));


export const CusLoadingButton = styled(LoadingButton)(({ theme }) => ({
  background: 'linear-gradient(180deg, #FEC682 0%, #FDB38F 100%)',
  color: "white",
  boxShadow: "unset",
  "&:hover": {
    boxShadow: 'rgb(0,0,0,0.15) 0 0 15px 2px',
    color: "white",
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '1rem',
  },
}));

export const CustomInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  color: '#555',
  marginTop: '0.5rem',
  '& .MuiSelect-select': {
    display: 'inline-flex',
  },
  '& label.Mui-focused': {
    color: '#28A3AB',
  },
  '& .MuiOutlinedInput-root': {
    color: '#28A3AB',
    '& fieldset': {
      borderColor: '#28A3AB',
    },
    '&:hover fieldset': {
      borderColor: '#28A3AB',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#28A3AB',
    },
  },
  '& > label': {
    color: '#28A3AB !important',
  },
  '& .MuiOutlinedInput-root:hover': {
    '& > fieldset': {
      color: '28A3AB',
      boxShadow: '#28A3AB 0 0 10px',
    },
  },
}));
const loginInfo = {
  user: "petrussiqui@gmail.com",
  password: "09101997",
  gaCode: "091097",
  token:
    "U2FsdGVkX19pCwK8ruIRW+khbI2Hy30tFkFQDh+Jomj6aI79oqR4t24fQKaAlyGDjUFaUz7qZw9lh8AEiTNOf3DKbFwor4jFefJ3SHg9EMX0l7mkR/rMrPZnNGQ8XPiv+OafJ5I0lHZu0BeeAnTzOHwL8zvFcWviKPYIq451Z3VaWa6pHmUTe1vYedWXZUNlHzFJOWjize5PRT1GvzpPTrkjSkxLm8hqCI4IQ5MOlVDcugUOwoWX3T99Ix4ouTlcX8m5VPf7ncO5MR2VxS+L8A+eTgNk41Z07fY8r8KtXwp4SEYxZABGIql9fMT+HruG7tYSSyxJA5vRLAJNs4IaNe3G0Tqx1smH0IIF6Eut26wng6JAAMZLz2xQtnTMDmO0devUYdo9p06mNAHr0g8e+yZaGCDHekxPpW9yKG/HmDJveXtaSs//vslHCrPBgnHUiWtY205Drs4ObO081KGtt5sTgfmU2ny5+4JR1XcZ8gaZpYua1PgZi5uE1kDQeiB+xM7DX44I572dvlblIkEdPcMDpSqbX8yQyeDWK7J2vkzwAJbfvMQVsFeEvB66HyJVRUtoARX8LDnvCGGhggFLE3t47Iu0cTerKVSMa3JnMQgh5kUgNyVUlc/R8PCIQw0K/ngJLZYkyxfL1ks6J+xYt3iX7+nIWvEX3hSixkpDhZzBslowLG9HGYDv5kW5mfGlp2GfrZWRvmmS5EyfUiYjuy5iZOgUE7VzZBgQD214MaYKURPBmA3L9w+qLhXymXdiMXnwRmCfuZGaBJodjMOHJSzWAn2QZZeFXl2+PVCtINSUpGRu+4moXKFm/cYyHdUaW1zfg0B+ttQVZ98jrZxoy7o7VDzDGiBIl2t2uq6eyDVJ2dnRoKuthyFQx2o3eXlh5HzX+BUHXXnZ3YZ5JMGupRvl0tqFYrKhznqOfMWwmFprjxTHQ3H/qXn+/kIjfqGH9dXdR7m3tnkTOmZpilwpfQRIB3vnMRqHhFf4Njx4Xnht68QJCodftUpshEFFkg6RZbCUbQjx9CsUZzmkxxL0qfkXqLZHydpesOWX4wvJZZoQrSV8VhuqP/vVtP2J5ef1UpM0LzapvHTbDTFy7qytC94IIbTgOWjfCQcPPfl94WtYOihaUOXKRUK6CPEBjCnUUHpGvRXoQhcSrS8ECdql/RsriIJtuByFBd/ZeL8szmbqM/TDZWmGTq7f564EPE+mHSssGGZt0mH3/yGZFDBXPZ1GRdRDpI+5X91f7xesckzpOuHw1Y32g6tb3tET6q5f5558ub/OLaeEUDv4kphy24A/ZMDTcAKrJTUww7AclmyU3fMspYJDPP/gBPpvE/hliQf5e+FDdxIYg3ps5ygeGdHZOfjlr6fY6q4RfaHJW0253HUbV4azrd9C8k3FYKptFWmNXY+ddFoZkJd4Ol9foRIqtvkJwkAaTuyFhmPjdzougr/k2/tVj7K6uSm4peZsVP5GZ/qJ+lz19QyKB2ND64YPZRY693S8MIRsrWuvclj7i9bpw4Id9JPesHC3sPnYKhVBlNfsvc09isgHriEnyw==",
};

export default function LoginForm() {
  const navigate = useNavigate();
  const { admin } = useSelector((state) => state);
  const { information } = admin;
  const dispatch = useDispatch();

  const [step, setStep] = useState(1);

  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [gaCode, setGaCode] = useState(null);

  const LoginValues = {
    email: "",
    password: "",
    gaCode: "",
  };
  const LoginSchema = Yup.object().shape({
    email:
      step === 1 &&
      Yup.string()
        .email("Email must be a valid email address")
        .required("Email is required"),
    password:
      step === 1 && Yup.string().required("Password is required").min(8),
    gaCode:
      step === 2 &&
      Yup.string().required("Google authenticator code is required").length(6),
  });

  const formik = useFormik({
    initialValues: LoginValues,
    validationSchema: LoginSchema,
    onSubmit: () => {
      if (step === 1) handleCheckGa(formik.values);
      else handleLogin(formik.values);
    },
  });

  const { errors, touched, handleSubmit, getFieldProps } = formik;
  const handleCheckGa = (params) => {
    post(
      IdentityEndpoint.ADMIN_CHECK_GA,
      {
        email: params.email,
      },
      (result) => {
        if (result) setStep(2);
        else handleLogin(params);
      },
      (err) => {
        toast.error(err.title);
      }
    );
  };

  const handleLogin = (params) => {
    const submitParams = {
      email: params.email,
      password: params.password,
      gaCode: params.gaCode,
    };

    setIsSubmitting(true);
    post(
      IdentityEndpoint.ADMIN_LOGIN,
      submitParams,
      (data) => {
        setIsSubmitting(false);
        setAccessToken(data.accessToken);
        dispatch(_getAdminProfile());
        // setTimeout(() => {
        //   navigate('/dashboard/overview', { replace: false });
        // }, 500);
      },
      (err) => {
        setIsSubmitting(false);
        toast.error(err.title);
        // if (err.title === "GACODE_REQUIRED") setStep(2);
      }
    );
  };

  return (
    <LoginBox>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          {step === 1 && (
            <Stack spacing={3}>
              <Typography color={"#777"}>Enter your details below.</Typography>
              <CustomInput
                size="medium"
                fullWidth
                autoComplete="email"
                type="email"
                label="Email address"
                {...getFieldProps("email")}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />

              <CustomInput
                size="medium"
                fullWidth
                autoComplete="password"
                type={showPassword ? "text" : "password"}
                label="Password"
                {...getFieldProps("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {showPassword ? (
                          <IconEye size="20" />
                        ) : (
                          <IconEyeOff size="20" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <CusLoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Login
              </CusLoadingButton>
            </Stack>
          )}
          {step === 2 && (
            <Stack spacing={3}>
              <Typography>Enter your GA code</Typography>
              <CustomInput
                size="medium"
                fullWidth
                autoComplete="ga-code"
                type="text"
                label="Google authenticator code"
                {...getFieldProps("gaCode")}
                error={Boolean(touched.gaCode && errors.gaCode)}
                helperText={touched.gaCode && errors.gaCode}
              />

              <CusLoadingButton
                fullWidth
                size="medium"
                type="submit"
                variant="contained"
                loading={isSubmitting}
              >
                Submit
              </CusLoadingButton>
            </Stack>
          )}
        </Form>
      </FormikProvider>
    </LoginBox>
  );
}
