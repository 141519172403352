import { Clear, Wallpaper } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  AppBar,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { json } from "react-router-dom";
import { toast } from "react-toastify";
import { EndpointConstant } from "../constants/endpoint";
import { _switchPopup } from "../store/setting/settingActions";
import { post, postWithFormData, put } from "../utils/api";
import ItemField from "./ItemField";
export default function ItemCreate({
  open,
  createFields,
  updateFields,
  endpoint,
  type,
  defaultData,
  updateBy,
  _onReload,
  _onClose,
  updateMethod,
  ...props
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [defaultScopes, setDefaultScopes] = useState([]);

  useEffect(() => {
    if (defaultData.scopes) {
      setDefaultScopes(defaultData.scopes);
    }
  }, [defaultData.scopes]);

  const fields = type === "update" ? updateFields : createFields;

  const _handleCreate = (e) => {
    e.preventDefault();
    const body = {};
    try {
      fields.forEach((element) => {
        if (element.type === "checkbox") {
          body[element.key] = defaultScopes;
        } else if (element.type === "singleCheckbox") {
          const checked = e.target[element.key].checked;
          body[element.key] = checked;
        } else if (element.key === "roundNumber") {
          body[element.key] = parseInt(e.target.roundNumber.value);
        } else if (element.type === "special") {
          body[element.key] = JSON.parse(e.target[element.key].value);
          if (element.key === "profits") {
            let total = 0;
            const length = JSON.parse(e.target[element.key].value).length;
            console.log(length);
            JSON.parse(e.target[element.key].value).forEach((item) => {
              total = total + parseFloat(item.percentage);
            });
            if (length > 0 && total !== 100) {
              throw toast.error("Total percentage is 100%");
            }
          }
          if (element.key === "vesting") {
            body[element.key] = JSON.parse(e.target[element.key].value)[0];
          }
        } else if (element.type === "object") {
          body[element.key] = JSON.parse(e.target[element.key].value);
        } else if (element.isEnum) {
          body[element.key] = e.target[element.key].value
            ? parseFloat(e.target[element.key].value)
            : 0;
        } else {
          const value = e.target[element.key].value.trim();
          if (element.require && value === "") {
            throw toast.error("Please enter all the information ... !!!");
          } else {
            body[element.key] = value;
          }
        }
      });
      setLoading(true);
      console.log(body);
      dispatch(
        _switchPopup({
          title: "Notification",
          content: "Are you for this action",
          _handleSubmit: () => {
            if (type === "update") {
              body[updateBy ? updateBy : "id"] =
                defaultData[updateBy ? updateBy : "id"];
              if (updateMethod === "POST") {
                post(
                  endpoint,
                  body,
                  () => {
                    toast.success("Updated");
                    setLoading(false);
                    _onClose();
                    _onReload();
                  },
                  (error) => {
                    toast.error(error.title + ": " + error.detail);
                    setLoading(false);
                  }
                );
              } else {
                put(
                  endpoint,
                  body,
                  () => {
                    toast.success("Updated");
                    setLoading(false);
                    _onClose();
                    _onReload();
                  },
                  (error) => {
                    toast.error(error.title + ": " + error.detail);
                    setLoading(false);
                  }
                );
              }
            } else {
              post(
                endpoint,
                body,
                () => {
                  toast.success("Created");
                  setLoading(false);
                  _onClose();
                  _onReload();
                },
                (error) => {
                  setLoading(false);
                  toast.error(error.title + ": " + error.detail);
                }
              );
            }
          },
          _handleCancel: () => setLoading(false),
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  const _handleCheck = (e) => {
    const { checked, name } = e.target;
    console.log(checked);
    let temp = [...defaultScopes];
    if (checked) {
      temp.push(name);
      setDefaultScopes(temp);
    } else {
      temp = temp.filter((item) => item !== name);
      setDefaultScopes(temp);
    }
  };

  return (
    <Drawer anchor="right" open={open}>
      <Box width={600} className="item-detail">
        <div>
          <AppBar position="sticky" sx={{ py: 1 }}>
            <Grid container alignItems="center">
              <Grid item>
                <IconButton onClick={_onClose} disabled={loading}>
                  <ArrowBackIcon fontSize="small" sx={{ fill: "#fff" }} />
                </IconButton>
              </Grid>
              <Grid item>
                <Typography variant="body1">Back</Typography>
              </Grid>
            </Grid>
          </AppBar>
          {props.componentKey === "BANNER_LIST" ? (
            <UploadListComponent
              _onReload={_onReload}
              _onClose={_onClose}
              defaultData={defaultData}
            />
          ) : (
            <form style={{ padding: 16 }} onSubmit={_handleCreate}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">
                    {type === "update" ? "Update item" : "Add new item"}
                  </Typography>
                </Grid>
                {fields.map((item, index) => {
                  return (
                    <ItemField
                      data={item}
                      key={index}
                      defaultScopes={defaultScopes}
                      _handleCheck={_handleCheck}
                      defaultData={defaultData[item.key]}
                    />
                  );
                })}
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <span>Submit</span>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </form>
          )}
        </div>
      </Box>
    </Drawer>
  );
}

function isFileImage(file) {
  const acceptedImageTypes = ["image/gif", "image/jpeg", "image/png"];
  return file && acceptedImageTypes.includes(file["type"]);
}

const UploadListComponent = ({ defaultData, _onReload, _onClose }) => {
  const [imagesPreview, setImagesPreview] = useState([]);
  const [images, setImages] = useState([]);
  const imageSelector = useRef(null);
  const [medias, setMedias] = useState([]);
  const [loading, setLoading] = useState(false);
  const [information, setInformation] = useState([]);

  useEffect(() => {
    if (defaultData.medias) {
      const temp = [];
      const tempImages = [];
      defaultData?.medias?.forEach((media, index) => {
        temp.push(media.url);
        tempImages.push(index);
      });
      setImages(tempImages);
      setMedias(defaultData?.medias);
      setImagesPreview(temp);
      setInformation(
        defaultData.description ? JSON.parse(defaultData.description) : []
      );
    }
  }, [defaultData]);

  const _handleSelectImage = (e) => {
    const { files } = e.target;
    const tempImages = [...images];
    const tempMedias = [...medias];
    const tempImagesPreview = [...imagesPreview];
    const tempInformation = [...information];
    for (let index = 0; index < files.length; index++) {
      const element = files[index];
      if (isFileImage(element)) {
        tempImages.push(element);
        tempMedias.push({
          name: element.name,
          type: 0,
        });
        tempImagesPreview.push(URL.createObjectURL(element));
        tempInformation.push({
          screen: "",
          link: "",
          index: 0,
        });
      }
    }
    setImagesPreview(tempImagesPreview);
    setImages(tempImages);
    setMedias(tempMedias);
    setInformation(tempInformation);
  };

  const _handleRemoveImage = (index) => {
    setImages((preImages) => {
      preImages.splice(index, 1);
      return [...preImages];
    });
    setImagesPreview((preImages) => {
      preImages.splice(index, 1);
      return [...preImages];
    });
    setMedias((preImages) => {
      preImages.splice(index, 1);
      return [...preImages];
    });
    setInformation((preImages) => {
      preImages.splice(index, 1);
      return [...preImages];
    });
  };

  const _handleUpload = (e) => {
    setLoading(true);
    e.preventDefault();
    var body = new FormData();
    body.append("title", e.target.title.value);
    body.append("description", JSON.stringify(information));
    body.append("systemCode", e.target.systemCode.value);
    body.append("isDeleted", e.target.isDeleted.checked);
    images.forEach((item) => {
      if (!Number.isInteger(item)) {
        body.append("files", item);
      }
    });
    body.append("medias", JSON.stringify(medias));
    if (defaultData.medias) {
      body.append("id", defaultData.id);
    }
    postWithFormData(
      defaultData.medias
        ? EndpointConstant.BannerUpdate
        : EndpointConstant.BannerAdd,
      body,
      () => {
        _onReload();
        _onClose();
        setLoading(false);
        toast.success("Success");
      },
      (error) => {
        setLoading(false);
        toast.error(error.title + ": " + error.detail);
      }
    );
  };

  const _onchangeData = (index, key, value) => {
    setInformation((information) => {
      information[index][key] = value;
      return [...information];
    });
  };

  return (
    <form style={{ padding: 16 }} onSubmit={_handleUpload}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5">Upload</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Code"
            name="systemCode"
            defaultValue={defaultData.systemCode}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Title"
            name="title"
            defaultValue={defaultData.title}
          />
        </Grid>
        <Grid item xs={12}>
          <Box ml={1.5}>
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked={defaultData?.isDeleted}
                  name="isDeleted"
                  size="small"
                />
              }
              label={"Is deleted"}
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <IconButton>
            <label
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <input
                type="file"
                onChange={(e) => _handleSelectImage(e)}
                accept="image/*"
                hidden
                multiple
                ref={imageSelector}
              />
            </label>
            <Wallpaper />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          {imagesPreview && imagesPreview.length > 0 && (
            <RenderImagesPreview
              imagesPreview={imagesPreview}
              onRemove={(index) => _handleRemoveImage(index)}
              information={information}
              _onchangeData={_onchangeData}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            color="primary"
            type="submit"
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : <span>Submit</span>}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

const RenderImagesPreview = ({
  imagesPreview,
  onRemove,
  _onchangeData,
  information,
}) => {
  return (
    <Grid container spacing={1}>
      {imagesPreview.map((item, index) => (
        <>
          <Grid item xs={4} key={index} style={{ position: "relative" }}>
            <img
              src={item}
              alt=""
              key={index}
              style={{
                height: 200,
                width: "100%",
                objectFit: "contain",
                borderRadius: 12,
                background: "rgba(0, 0, 0, 0.1)",
              }}
            />
            <IconButton
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                background: "#fff",
                opacity: 0.8,
              }}
              size="small"
              onClick={() => onRemove(index)}
            >
              <Clear fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={8}>
            <TextField
              fullWidth
              label="Index"
              value={information[index].index}
              onChange={(e) => _onchangeData(index, "index", e.target.value)}
            />
            <TextField
              fullWidth
              sx={{ my: 2 }}
              label="Screen"
              value={information[index].screen}
              onChange={(e) => _onchangeData(index, "screen", e.target.value)}
            />
            <TextField
              fullWidth
              label="Link"
              value={information[index].link}
              onChange={(e) => _onchangeData(index, "link", e.target.value)}
            />
          </Grid>
        </>
      ))}
    </Grid>
  );
};
